import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Menu, MenuItem, Divider } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import musicImg from "../../Assets/musicnote.svg"; // Change to episode-specific image if you have one
import "./CreatedEpisodeItem.scss";
import { getS3BucketUrl } from "../../helper/constants";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";

const ITEM_HEIGHT = 48;

const CreatedEpisodeItem = ({
  episode,
  handleClick,
  anchorEl,
  open,
  handleClose,
  handlePublish,
  handleUnpublish,
  handleDelete,
  handleArchive,
  handleEdit,
  isPublished,
  onPlayPause,
  currentlyPlaying,
}) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handlePlayPause = () => {
    if (currentlyPlaying) {
      setIsPlaying(!isPlaying);
    } else {
      setIsPlaying(true);
    }
    // setIsPlaying(!isPlaying);
    onPlayPause(audioRef);
  };

  return (
    <div className="episodeItem">
      <div className="episodeItemLeft">
        <img
          src={episode.image ? getS3BucketUrl(episode.image) : musicImg}
          className="episodeImage"
          alt={episode.title || "No title"}
          style={{ cursor: episode.audio_file ? "pointer" : "default" }}
        />
        <div className="episodeInfo">
          <h3 style={{ width: "100%" }}>{episode.title || "Test"}</h3>
          <p style={{ marginTop: "-5px" }}>
            {new Date(episode.release_date).getTime() > Date.now()
              ? `Scheduled to ${new Date(
                  episode.release_date
                ).toLocaleDateString()}`
              : `Released on ${new Date(
                  episode.release_date
                ).toLocaleDateString()}`}
          </p>
        </div>
      </div>
      <div className="episodeItemRight">
        <audio ref={audioRef} src={getS3BucketUrl(episode.audio_file)}></audio>
        <IconButton
          color="primary"
          onClick={handlePlayPause}
          disabled={!episode.audio_file}
        >
          {currentlyPlaying && isPlaying ? (
            <PauseCircleFilledIcon style={{ fontSize: 40 }} />
          ) : (
            <PlayCircleFilledIcon style={{ fontSize: 40 }} />
          )}
        </IconButton>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={(event) => handleClick(event, episode)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              borderRadius: "8px",
              border: "1px solid #ddd",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          {episode.active === 1 && [
            <MenuItem key="unpublish" onClick={handleUnpublish} disableRipple>
              <UploadIcon className="icons" />
              UnPublish
            </MenuItem>,
          ]}

          {episode.active === 0 && [
            <MenuItem key="publish" onClick={handlePublish} disableRipple>
              <UploadIcon className="icons" />
              Publish
            </MenuItem>,
            <Divider key="divider1" sx={{ my: 0.3 }} />,
            <MenuItem key="delete" onClick={handleArchive} disableRipple>
              <DeleteIcon className="icons" />
              Archive
            </MenuItem>,
          ]}
          {episode.active === -1 && [
            <MenuItem key="publish" onClick={handleUnpublish} disableRipple>
              <UploadIcon className="icons" />
              Unpublish
            </MenuItem>,
            <Divider key="divider1" sx={{ my: 0.3 }} />,
            <MenuItem key="delete" onClick={handleDelete} disableRipple>
              <DeleteIcon className="icons" />
              Delete
            </MenuItem>,
          ]}
        </Menu>
      </div>
    </div>
  );
};

CreatedEpisodeItem.propTypes = {
  episode: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePublish: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  isPublished: PropTypes.bool.isRequired,
  onPlayPause: PropTypes.func.isRequired,
};

export default CreatedEpisodeItem;
