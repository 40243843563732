// reducers/uploadReducer.js
import {
    UPLOAD_START,
    UPLOAD_PROGRESS,
    UPLOAD_SUCCESS,
    UPLOAD_FAILURE,
  } from '../actions/uploadActions';
  
  const initialState = {
    uploading: false,
    progress: 0,
    error: null,
  };
  
  const uploadReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPLOAD_START:
        return {
          ...state,
          uploading: true,
          progress: 0,
          error: null,
        };
      case UPLOAD_PROGRESS:
        return {
          ...state,
          progress: action.payload,
        };
      case UPLOAD_SUCCESS:
        return {
          ...state,
          uploading: false,
          progress: 100,
          error: null,
        };
      case UPLOAD_FAILURE:
        return {
          ...state,
          uploading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default uploadReducer;
  