import React from 'react';
import './buttons.scss'; // Import your CSS file for styling

const TextButton = ({ text, onClick, loading = false }) => {
    return (
        <div style={{
            width: '100%',
            margin: '8px',
            display:'flex',
        }}>
            {
                loading ?
                    <div className="d-flex justify-content-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    :
                    <div
                        className="text-button btn-sm"
                        onClick={onClick}
                        style={{ width: '100%' }}
                    >
                        {text}
                    </div>
            }
        </div>
    );
}

export default TextButton;
