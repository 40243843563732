import React, { useEffect, useState } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import { fetchCreatedEpisodes, reviewMusic, unpublishMusic, deleteMusic, archiveMusic } from '../../helper/musicHelper'; // Create a helper function to fetch episodes
import './CreatedEpisode.scss';
import { Toaster, toast } from 'react-hot-toast';
import episodeImage from '../../Assets/microphone-2.svg';
import { useNavigate } from 'react-router-dom'; 
import CreatedEpisodeItem from './CreatedEpisodeItem';
import Modal from 'react-modal';
import checked from '../../Assets/checked.png';

Modal.setAppElement('#root');  


const CreatedEpisode = () => {
  const [episodeList, setEpisodeList] = useState([]);
  const [unpublishedEpisodeList, setUnpublishedEpisodeList] = useState([]);
  const [archivedEpisodeList, setArchivedEpisodeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('published'); 
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const openConfirmation = (episode) => {
    handleClose();
    setConfirmationIsOpen(true);
  };

  const closeConfirmation = () => {
    setSelectedEpisode(null);
    setConfirmationIsOpen(false);
  };

  const handleClick = (event, episode) => {
    setAnchorEl(event.currentTarget);
    setSelectedEpisode(episode);
  };
  const handleClose = () => { 
    setAnchorEl(null);
    // setSelectedEpisode(null); 
  };

  const handleEdit = () => {
    const serializableEpisode = {
      id: selectedEpisode.id,
      title: selectedEpisode.title || 'Unknown',
      writers: selectedEpisode.writers,
      artists_names: selectedEpisode.artists_names,
      genre: selectedEpisode.category_id,
      language: selectedEpisode.language,
      image: selectedEpisode.image,
      audio_file: selectedEpisode.audio_file,
      // Add other necessary properties here
    };

    // console.log(serializableMusic);
    navigate('/publish-episode', { state: { selectedEpisode: serializableEpisode } });
  };

  const handlePublish = async () => {
    if (!selectedEpisode) return;

    try {
      await reviewMusic(selectedEpisode.id); // Simulate API call to publish music

      // Update the selected music's active status to 1 
      const updatedEpisode = { ...selectedEpisode, active: 1 };

      setUnpublishedEpisodeList(prev => prev.filter(episode => episode.id !== selectedEpisode.id));

      setEpisodeList(prev => [...prev, updatedEpisode]);
      
      toast.success("Episode Published Successfully");
    } catch (error) {
      toast.error('Failed to publish episode');
    } finally {
      handleClose();
    }
  };

  const handleUnpublish = async () => {
    if (!selectedEpisode) return;

    try {
      await unpublishMusic(selectedEpisode.id); // Simulate API call to publish music

      // Update the selected episode's active status to -1 (archived)
      const updatedEpisode = { ...selectedEpisode, active: 0 };

      if (activeTab === 'published') {
        setEpisodeList(prev =>
          prev.filter(music =>
            music.id !== selectedEpisode.id
          )
        );
      } else if (activeTab === 'archived') {
        setArchivedEpisodeList(prev =>
          prev.filter(music =>
            music.id !== selectedEpisode.id
          )
        );
      }

      setUnpublishedEpisodeList(prev => [
        ...prev,
        updatedEpisode
      ]);
      toast.success('Episode Unpublished Succesfully');
    } catch (error) {
      toast.error('Failed to Unpublish episode');
    } finally {
      handleClose();
    }
  };

  // console.log(selectedEpisode);
  const handleDelete = async () => {
    if (!selectedEpisode) return;
  
    try {
      // console.log(selectedEpisode.id);
      await deleteMusic(selectedEpisode.id); // API call to delete music
  
      // Remove the deleted music from the appropriate list
      if (selectedEpisode.active === 1) {
        setEpisodeList((prevList) => prevList.filter((episode) => episode.id !== selectedEpisode.id));
      } else if (selectedEpisode.active === 0){
        setUnpublishedEpisodeList((prevList) => prevList.filter((episode) => episode.id !== selectedEpisode.id));
      }
      else{
        setArchivedEpisodeList((prevList) => prevList.filter((episode) => episode.id !== selectedEpisode.id))
      }
  
      toast.success('Episode deleted successfully');
    } catch (error) {
      // console.error('Error deleting episode:', error);
      toast.error('Failed to delete episode');
    } finally {
      closeConfirmation();
    }
  };

  const handleArchive = async () => {
    if (!selectedEpisode) return;

    try {
      await archiveMusic(selectedEpisode.id);

      // Update the selected episode's active status to -1 (archived)
      const updatedEpisode = { ...selectedEpisode, active: -1 };

      if (selectedEpisode.active === 0) {
        setUnpublishedEpisodeList((prevList) => prevList.filter((music) => music.id !== selectedEpisode.id));
      } 

      setArchivedEpisodeList(prev => [
        ...prev,
        updatedEpisode
      ]);
  
      toast.success('Music archived successfully');
    } catch (error) {
      toast.error('Failed to archive music');
    } finally {
      closeConfirmation();
    }
  };

  useEffect(() => {
    const loadCreatedEpisodes = async () => {
      try {
        const episodeData = await fetchCreatedEpisodes();
        // console.log(episodeData);
        setEpisodeList(episodeData.filter(episode => episode.active === 1));
        setUnpublishedEpisodeList(episodeData.filter(episode => episode.active === 0));
        setArchivedEpisodeList(episodeData.filter(episode => episode.active === -1));
      } catch (error) {
        toast.error('Failed to fetch episodes');
      } finally {
        setLoading(false);
      }
    };

    loadCreatedEpisodes();
  }, []);

  useEffect(() => {
    // Stop currently playing music when switching tabs
    if (currentlyPlaying) {
      currentlyPlaying.audioRef.current?.pause();
      setCurrentlyPlaying(null);
    }
  }, [activeTab]);

  const handlePlayPause = (musicId, audioRef) => {
    if (currentlyPlaying && currentlyPlaying.audioRef && currentlyPlaying.id !== musicId) {
      currentlyPlaying.audioRef.current?.pause();
      currentlyPlaying.audioRef.current.currentTime = 0; // Reset time to start
    }

    if (currentlyPlaying && currentlyPlaying.id === musicId) {
      if (currentlyPlaying.audioRef.current) {
        if (currentlyPlaying.audioRef.current.paused) {
          currentlyPlaying.audioRef.current.play();
        } else {
          currentlyPlaying.audioRef.current.pause();
        }
      }
    } else {
      audioRef.current?.play();
      setCurrentlyPlaying({ id: musicId, audioRef });

      // Listen for the 'ended' event to reset the currently playing state when the song ends
      audioRef.current.onended = () => {
        setCurrentlyPlaying(null);
      };
    }
  };


  const renderEpisodeList = () => {
    let list = [];
    switch (activeTab) {
      case 'published':
        list = episodeList;
        break;
      case 'unpublished':
        list = unpublishedEpisodeList;
        break;
      case 'archived':
        list = archivedEpisodeList;
        break;
      default:
        list = episodeList;
        break;
    }

    if (loading) {
      return <div>Loading...</div>;
    }

    if (list.length > 0) {
      return (
        <div className="episodeList">
          {list.map((episode) => (
            <CreatedEpisodeItem
              key={episode.id}
              episode={episode}
              handleClick={handleClick}
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              handlePublish={handlePublish}
              handleUnpublish={handleUnpublish}
              handleDelete={() => openConfirmation(episode)}
              handleArchive={() => openConfirmation(episode)}
              handleEdit={handleEdit}
              isPublished={activeTab === 'published'}
              onPlayPause={(audioRef) => handlePlayPause(episode.id, audioRef)}
              currentlyPlaying={currentlyPlaying?.id === episode.id}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className="emptyState">
          <img src={episodeImage} alt="No music" />
          <p>Add episodes to see here</p>
        </div>
      );
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <main className="content">
        <div className="homeContainer">
          <Toaster />
          <h1>My Assets (Episodes)</h1>
          <div className="tabs">
            <button
              className={activeTab === 'published' ? 'active' : ''}
              onClick={() => setActiveTab('published')}
            >
              Published
            </button>
            <button
              className={activeTab === 'unpublished' ? 'active' : ''}
              onClick={() => setActiveTab('unpublished')}
            >
              Unpublished
            </button>
            <button
              className={activeTab === 'archived' ? 'active' : ''}
              onClick={() => setActiveTab('archived')}
            >
              Archived
            </button>
          </div>
          {renderEpisodeList()}
        </div>
        <Modal
          isOpen={confirmationIsOpen}
          onRequestClose={closeConfirmation}
          contentLabel="Confirm Delete"
          style={{
            content: {
              maxHeight: '190px',
              borderRadius: '12px'
            }
          }}
        >
        <img src={checked} alt="" style={{
          width: '48px',
          height: '48px',
          marginBottom: '-35px'
        }} />
          <h2>Confirmation</h2>
          <p style={{ textAlign: 'center', marginTop: '-5px', fontSize: '0.85em' }}>
            {selectedEpisode && selectedEpisode.active === 0 
              ? 'Are you sure you want to archive this episode?' 
              : 'Are you sure you want to delete this episode?'}
          </p>
          <div className="button-group">
            <button type="button" className='sub-can-button' onClick={closeConfirmation}>No</button>
            <button type="submit" className='sub-can-button' onClick={selectedEpisode && selectedEpisode.active === 0 ? handleArchive : handleDelete}>Yes</button>
          </div>
        </Modal>
      </main>
    </div>
  );
};

export default CreatedEpisode;
