import { red } from "@mui/material/colors"


const RequiredMark = () => {
    return <>
        <span style={{
            color: 'red'
        }}>
            *
        </span>

    </>
}

export default RequiredMark;