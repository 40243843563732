import './../buttons/buttons.scss'; // Import your CSS file for styling (if using external CSS)


const LoadingSpinner = () => {
    return <>
        <div className="d-flex justify-content-center mt-5">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div></>;
}

export default LoadingSpinner;