import { Navigate, useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/store";
import PropTypes from 'prop-types';
import axios from "axios";
import { apiSecureHeader } from "../helper/constants";

export const AuthorizeUser = ({ children }) => {
    const token = localStorage.getItem('token');
    if (!token) {
        return <Navigate to={'/auth'} replace={true} />;
    }

    return children;
};

AuthorizeUser.propTypes = {
    children: PropTypes.node.isRequired
};

export const RedirectIfAuthenticated = ({ children }) => {
    const token = localStorage.getItem('token');
    if (token) {
        return <Navigate to={'/'} replace={true} />;
    }
    return children;
};

RedirectIfAuthenticated.propTypes = {
    children: PropTypes.node.isRequired
};
