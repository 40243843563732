import React, { useState, useRef } from 'react';

const OtpInput = () => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const refs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    const handleChange = (index, value) => {
        if (!isNaN(value) && value.length === 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move focus to the next input field
            if (index < 5 && value.length === 1) {
                refs[index + 1].current.focus();
            }
        }
    };

    const handleBackspace = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            const newOtp = [...otp];
            newOtp[index - 1] = '';
            setOtp(newOtp);
            refs[index - 1].current.focus();
        }
    };

    return (
        <div className="otp-input-container">
            {otp.map((digit, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleBackspace(index, e)}
                    ref={refs[index]}
                    className="otp-input"
                />
            ))}
        </div>
    );
};

export default OtpInput;
