import axios from 'axios';
import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { apiSecureHeader } from '../helper/constants';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

export const useAuthStore = create(immer((set) => ({
    auth: {
        email: '',
        active: false,
        isAuthenticated: !!localStorage.getItem('token'),
        user: null,  // Added user data to the store
    },
    setEmail: (mail) => set((state) => {
        state.auth.email = mail;
        state.auth.isAuthenticated = true;
    }),
    setUser: (userData) => set((state) => {
        state.auth.user = userData; // Method to set user data
    }),
    logout: () => set((state) => {
        axios.get('https://hallelujahofficial.com/api/logOutApi', {
            headers: {
                'secure': apiSecureHeader,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'is_admin': true,
            },
        })
            .then(() => {
                // Clear the state if logout is successful



            })
            .catch((error) => {
                
                
            });
        state.auth.email = '';
        state.auth.isAuthenticated = false;
        state.auth.user = null;
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    })

})));
