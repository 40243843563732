import React, { useEffect, useState } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import { useLocation, useNavigate } from 'react-router-dom';
import RoundedButton from '../../components/buttons/RoundedButton';
import "./CreateEpisode.scss";
import mic from '../../Assets/mic.svg';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import Divider from '@mui/material/Divider';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { apiSecret1, apiSecret2, apiSecureHeader } from '../../helper/constants';
import { Typography } from '@mui/material';
import RecordItem from './RecordItem';
import Modal from 'react-modal';
import checked from '../../Assets/checked.png';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import document from '../../Assets/document-upload.svg';
import video from '../../Assets/video.svg';
import dollar from '../../Assets/doller.svg';
import star from '../../Assets/star.svg';
import arrow from '../../Assets/arrow_right.svg';
import BackButton from '../../components/buttons/BackButton';
Modal.setAppElement('#root'); 


const CreateEpisode = () => {
  const navigate = useNavigate();
  // const [activeTab, setActiveTab] = useState('published');
  const [anchorEl, setAnchorEl] = React.useState("");
  const open = Boolean(anchorEl);
  const [segments, setSegments] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [renameIsOpen, setRenameIsOpen] = useState(false);
  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [confirmationIsOpenBulk, setConfirmationIsOpenBulk] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const location = useLocation();

  const openConfirmation = (episode) => {
    handleClose();
    setConfirmationIsOpen(true);
  };

  const closeConfirmation = () => {
    setSelectedSegment(null);
    setConfirmationIsOpen(false);
  };

  const openConfirmationBulk = () => {
    setConfirmationIsOpenBulk(true);
  }

  const closeConfirmationBulk = () => {
    setSelectedRecords([]);
    setConfirmationIsOpenBulk(false);
  }

  const handleClick = (event, segment) => {
    setAnchorEl(event.currentTarget);
    setSelectedSegment(segment);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedSegment(null);
  };

  const openRenameModal = (episode) => {
    handleClose();
    setRenameIsOpen(true);
    setNewTitle(selectedSegment.name || ""); // Pre-fill with current title
  };

  const closeRenameModal = () => {
    setRenameIsOpen(false);
    setNewTitle("");
  };

  const openCreateModal = () => {
    setCreateIsOpen(true);
  }

  const closeCreateModal = () => {
    setCreateIsOpen(false);
  }


  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await axios.get('https://hallelujahofficial.com/api/godcast/segments', {
          headers: { 'secure': apiSecureHeader, 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'is_admin': true}
        });
        setSegments(response.data);
        setLoading(false);
      } catch (error) {
        toast.error('Failed to fetch segments');
        setLoading(false);
      }
    };

    fetchSegments();
  }, []);

  // console.log(segments);

 

  useEffect(() => {
    // Stop currently playing music when switching tabs
    if (currentlyPlaying) {
      currentlyPlaying.audioRef.current?.pause();
      setCurrentlyPlaying(null);
    }
  }, []);

  const handlePlayPause = (musicId, audioRef) => {
    if (currentlyPlaying && currentlyPlaying.audioRef && currentlyPlaying.id !== musicId) {
      currentlyPlaying.audioRef.current?.pause();
      currentlyPlaying.audioRef.current.currentTime = 0; // Reset time to start
    }

    if (currentlyPlaying && currentlyPlaying.id === musicId) {
      if (currentlyPlaying.audioRef.current) {
        if (currentlyPlaying.audioRef.current.paused) {
          currentlyPlaying.audioRef.current.play();
        } else {
          currentlyPlaying.audioRef.current.pause();
        }
      }
    } else {
      audioRef.current?.play();
      setCurrentlyPlaying({ id: musicId, audioRef });

      // Listen for the 'ended' event to reset the currently playing state when the song ends
      audioRef.current.onended = () => {
        setCurrentlyPlaying(null);
      };
    }
  };


  const handlePublish = () => {
    navigate('/publish-episode');
  };

  const handleRecord = () => {
    navigate('/record');
  };

  // console.log(selectedSegment);

  const handleDelete = async () => {
    if (!selectedSegment) return;

    try {
      await axios.delete(`https://hallelujahofficial.com/api/godcast/segments/delete/` + selectedSegment.id, {
        headers: { 'secure': apiSecureHeader, 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'is_admin': true,
         }
      });
      setSegments(segments.filter(segment => segment.id !== selectedSegment.id));
      toast.success('Segment deleted successfully');
    } catch (error) {
      toast.error('Failed to delete segment');
    } finally {
      closeConfirmation();
    }
  };

  const handleEdit = async () => {
    // Check for title length
    if (newTitle.length > 50) {
      toast.error('Title cannot exceed 50 characters');
      return;
    }

    // Check for duplicate title in the segment
    const isDuplicate = segments.some(record => record.name.toLowerCase() === newTitle.toLowerCase());

    if (isDuplicate) {
      toast.error('Segment title already exists');
      return;
    }

    try {
      await axios.patch(`https://hallelujahofficial.com/api/godcast/segments/rename/` + selectedSegment.id, {
        name: newTitle,
        id: selectedSegment.id
      }, {
        headers: { 'secure': apiSecureHeader, 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'is_admin': true, }
      });
      setSegments(segments.map(segment => segment.id === selectedSegment.id ? { ...segment, name: newTitle } : segment));
      toast.success('Segment renamed successfully');
    } catch (error) {
      toast.error('Failed to rename segment');
    } finally {
      closeRenameModal();
    }
  };

  const handleBackground = () => {
    if (selectedSegment) {
      navigate('/background-music', { state: { selectedSegmentId: selectedSegment.id } });
    } else {
      // Handle cases where selectedBackgroundId is not set
      toast.error('No segment selected');
    }
  };

  // console.log(selectedSegment);
  const handleAudio = () => {
    if (selectedSegment){
      navigate('/edit-audio', { state: { audioFileId: selectedSegment.id,original_file:selectedSegment.original_file } })
    }
    else{
      toast.error('No segment selected');
    }
  }

  // Handle checkbox click
  const handleCheckboxChange = (recordId, isChecked) => {
    setSelectedRecords((prevSelected) => {
      if (isChecked) {
        return [...prevSelected, recordId];
      } else {
        return prevSelected.filter((id) => id !== recordId);
      }
    });
  };

  // Determine if the delete button should be enabled
  const isDeleteEnabled = selectedRecords.length > 0;

  const handleDeleteBulk = async (selectedRecordIds) => {
    if (!selectedRecordIds || selectedRecordIds.length === 0) return;
  
    try {
      await axios.post(`https://hallelujahofficial.com/api/godcast/segments/bulk/delete`, 
        { ids: selectedRecordIds },
        {
        headers: {
          'secure': apiSecureHeader,
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
          'is_admin': true,
        }
      });
  
      // Update the segments state by removing the deleted segments
      setSegments(segments.filter(segment => !selectedRecordIds.includes(segment.id)));
  
      toast.success('Selected Segments deleted successfully');
    } catch (error) {
      toast.error('Failed to delete selected segments');
      // console.log(error);
    } finally {
      closeConfirmationBulk();
    }
  
    // console.log('Deleting records with IDs:', selectedRecordIds);
  };
  
  
  

  return (
    <div className='container'>
      <Sidebar />
      <main className="content">
        <div className="homeContainer">
        <Toaster />
        <div className="heading">
          
          <p style={{fontSize: '40px'}}>Create an Episode</p>
          <ArrowDropDownCircleIcon style={{color: '#67519F', fontSize:'30px', cursor:'pointer'}} onClick={openCreateModal}/> 
        </div>
          
          <div className="episode-list" style={{marginTop:'20px'}}>
          {segments.length > 0 ? (
                <div className="recordList library">
                {segments.map((record) => (
                  <RecordItem
                    key={record.id}
                    record={record}
                    handleClick={handleClick}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    handleDelete={() => openConfirmation(record)}
                    handleBackground={handleBackground}
                    handleEdit={() => openRenameModal(record)}
                    handleAudio={handleAudio}
                    isSegment={true}
                    onPlayPause={(audioRef) => handlePlayPause(record.id, audioRef)}
                    currentlyPlaying={currentlyPlaying?.id === record.id}
                    onCheckboxChange={(isChecked) => handleCheckboxChange(record.id, isChecked)}
                  />
                ))}
              </div>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No segments found
                </Typography>
              )}
          </div>
          {isDeleteEnabled ? (
            <div className='button-group-1' style={{margin: '0 auto'}}>
              <RoundedButton type="delete" text="Delete Bulk Segments" onClick={openConfirmationBulk} />
            </div>
          ) : (
            <div className="button-group-1">
              <RoundedButton type="button" text="Add Segment" onClick={handleRecord} />
              <RoundedButton type="submit" text="Publish" onClick={handlePublish} disabled={segments.length === 0} />
            </div>
          )}
        </div>
        <Modal
          isOpen={confirmationIsOpen}
          onRequestClose={closeConfirmation}
          contentLabel="Confirm Delete"
          style={{
            content: {
              maxHeight: '190px',
              borderRadius: '12px'
            }
          }}
        >
        <img src={checked} alt="" style={{
          width: '48px',
          height: '48px',
          marginBottom: '-35px'
        }} />
          <h2>Confirmation</h2>
          <p style={{ textAlign: 'center', marginTop: '-5px', fontSize: '0.85em' }}>
            Are you sure you want to delete this segment?
          </p>
          <div className="button-group">
            <button type="button" className='sub-can-button' onClick={closeConfirmation}>No</button>
            <button type="submit" className='sub-can-button' onClick={handleDelete}>Yes</button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmationIsOpenBulk}
          onRequestClose={closeConfirmationBulk}
          contentLabel="Confirm Delete"
          style={{
            content: {
              maxHeight: '210px',
              borderRadius: '12px'
            }
          }}
        >
        <img src={checked} alt="" style={{
          width: '48px',
          height: '48px',
          marginBottom: '-35px'
        }} />
          <h2>Confirmation</h2>
          <p style={{ textAlign: 'center', marginTop: '-5px', fontSize: '0.85em' }}>
            Are you sure you want to delete selected segments?
          </p>
          <div className="button-group">
            <button type="button" className='sub-can-button' onClick={closeConfirmationBulk}>No</button>
            <button type="submit" className='sub-can-button' onClick={() => handleDeleteBulk(selectedRecords)}>Yes</button>
          </div>
        </Modal>
        <Modal
          isOpen={renameIsOpen}
          onRequestClose={closeRenameModal}
          contentLabel="Rename Segment"
          style={{
            content: {
              maxHeight: '200px',
              borderRadius: '12px',
              padding: '0px 50px 10px 30px'
            }
          }}
        >
          <h2>Rename Segment</h2>
          <input
            type="text"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            placeholder="Enter new title"
          />
          <div className="button-group" style={{marginLeft:'20px'}}>
            <button type="button" className='sub-can-button' onClick={closeRenameModal}>Cancel</button>
            <button type="submit" className='sub-can-button' onClick={handleEdit}>Save</button>
          </div>
        </Modal>
        <Modal
          isOpen={createIsOpen}
          onRequestClose={closeCreateModal}
          contentLabel="Steps Involved"
          style={{
            content: {
              maxHeight: '600px',
              maxWidth: '600px',
              borderRadius: '12px',
              padding: '0px 50px 10px 30px'
            }
          }}
        >
          <div className="timeline">
            <div className="timeline-item">
              <div className="timeline-icon">
                <img src={document} alt="Icon 1" />
              </div>
              <div className='timeline-info'>
                <h3>Create an episode</h3>
                <div style={{marginTop:'-5px', marginLeft:'-70px'}}>
                <p style={{fontSize: '12px'}}>Import an existing episode or creation tools. Then, publish it on Godcast.</p>
                </div>
              </div>
              
            </div>
            <div className="timeline-item">
              <div className="timeline-icon">
                <img src={video} alt="Icon 2" />
              </div>
              <div className='timeline-info'>
                <h3>Your podcast is all set up</h3>
              </div>

            </div>
            <div className="timeline-item">
              <div className="timeline-icon">
                <img src={dollar} alt="Icon 2" />
              </div>
              <div className='timeline-info'>
                <h3>Set up monetization</h3>
              </div>
              
            </div>
            <div className="timeline-item">
              <div className="timeline-icon">
                <img src={star} alt="Icon 2" />
              </div>
              <div className='timeline-info'>
                <h3>Start getting listeners</h3>
              </div>
              
            </div>
          </div>

          
          <div className="button-group">
            <button type="button" className='sub-can-button' onClick={closeCreateModal}>Close</button>
          </div>
        </Modal>
      </main>
    </div>
  );
};

export default CreateEpisode;
