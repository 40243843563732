import React from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import './linegraph.scss';
import noDataImage from './../../Assets/no-data.svg'; // Update the path to your image
import { RANGE_ALL_TIME, RANGE_MONTHLY, RANGE_NIGHENTY_DAYS, RANGE_WEEKLY } from '../../helper/constants';

const AreaChartComponent = ({ data, title, loading = false, onRangeChanged, currentRange }) => {

  // Conditional rendering based on data availability
  const renderContent = () => {
   

    if (!data || data.length === 0) {
      return (
        <div className="no-data">
          <img src={noDataImage} alt="No data" />
          <div>
          <p>Not enough data yet</p>

          </div>
        </div>
      );
    }

    return (
      <div className="barGraph">
        <ResponsiveContainer width="100%">
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="xAxis" />
          <YAxis />
          <CartesianGrid stroke="#f5f5f5" strokeDasharray="" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="yAxis"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
      </div>
    );
  };

  return (
    <div className="performance">
      <div className="subtabs-container2">
        <button
          className={currentRange === RANGE_WEEKLY ? 'active-subtab' : ''}
          onClick={() => onRangeChanged(RANGE_WEEKLY)}
        > Weekly</button>
        <button
          className={currentRange === RANGE_MONTHLY ? 'active-subtab' : ''}
          onClick={() => onRangeChanged(RANGE_MONTHLY)}
        > Monthly</button>
        <button
          className={currentRange === RANGE_NIGHENTY_DAYS ? 'active-subtab' : ''}
          onClick={() => onRangeChanged(RANGE_NIGHENTY_DAYS)}
        > 90 Days</button>
        <button
          className={currentRange === RANGE_ALL_TIME ? 'active-subtab' : ''}
          onClick={() => onRangeChanged(RANGE_ALL_TIME)}
        > All Time</button>
      </div>
      {renderContent()}
    </div>
  );
};

export default AreaChartComponent;
