import React from 'react';
import './buttons.scss'; // Import your CSS file for styling (if using external CSS)
import arrowRight from '../../Assets/arrow-right.svg';

const FilledButton = ({ text, onClick, loading = false, enabled = true }) => {
    return (
        <div style={{

            textAlign: 'center',
            width: '100%',
            margin: '8px'
        }}>
            {
                loading ?
                    <div className="d-flex justify-content-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>


                    : <button className="filled-button btn-sm"

                        

                        onClick={onClick} style={{
                            width: '100%',
                            display:enabled?'inline-block':'none'
                        }} 
                        
                        >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                            padding: '0px'
                        }}>
                            {text}
                            <img src={arrowRight} alt="" height='24px' style={{
                                paddingLeft: '8px'
                            }} />

                        </div>
                    </button>
            }

        </div>
    );
}

export default FilledButton;
