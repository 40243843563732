import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/store';
import { authenticate, verifyPassword } from '../../helper/helper';
import toast, { Toaster } from 'react-hot-toast';
import AuthContainer from '../../components/widget/AuthContainer';
import logo from '../../Assets/app_logo.png';
import FilledButton from '../../components/buttons/FilledButton';
import TextButton from '../../components/buttons/TextButton';


const toastOptions = {
    style: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
};

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const setEmailInStore = useAuthStore((state) => state.setEmail);
    const navigate = useNavigate();

    const handleSignIn = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');
        try {
             // Check if the email exists
            //  const authResponse = await authenticate(email);
            //  if (!authResponse.data.exists) {
            //      setErrorMessage('Email does not exist.');
            //      toast.error('Email does not exist. Please register to continue.', toastOptions);
            //      setLoading(false);
            //      return;
            //  }

            const response = await verifyPassword({ email, password });
            if (response) {
                setEmailInStore(email);
                localStorage.setItem('token', response.data.token); // Store the token
                localStorage.setItem('user', JSON.stringify(response.data.user));
                setErrorMessage('');
                toast.success('Sign-in successful');
                navigate('/'); // Navigate to home page
            }
        } catch (error) {
            setErrorMessage(error.error || 'Password does not match.');
            toast.error(error.error || 'Password does not match.', toastOptions);
        }
        setLoading(false);
    };

    return (
        <AuthContainer>
            <div style={{
                maxWidth: '500px',
                margin: 'auto',
                textAlign: 'center',
                padding: '1rem'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '1rem'
                }}>
                    <div style={{ marginRight: '0.5rem' }}>
                        <img src={logo} alt="" width="57px" height="57px" />
                    </div>
                    <h1 style={{
                        color: '#B57A25',
                        fontWeight: '700',
                        fontSize: '2rem',
                        margin: '0'
                    }}>
                        Godcast
                    </h1>
                </div>
                <h2 style={{ textAlign: 'center', margin: '1rem 0' }}>
                    Login To Continue
                </h2>
                <Toaster />
                <div>
                    <form onSubmit={handleSignIn} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign:'end'
                    }}>
                        <div style={{ width: '100%', marginBottom: '1rem' }}>
                            <label style={{
                                display: 'block',
                                textAlign: 'left',
                                marginBottom: '0.5rem',
                                fontWeight: 'bold'
                            }}>Email:</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                style={{
                                    width: '100%',
                                    padding: '0.5rem',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '1rem'
                                }}
                            />
                        </div>
                        <div style={{ width: '100%', marginBottom: '1rem' }}>
                            <label style={{
                                display: 'block',
                                textAlign: 'left',
                                marginBottom: '0.5rem',
                                fontWeight: 'bold'
                            }}>Password:</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                style={{
                                    width: '100%',
                                    padding: '0.5rem',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '1rem'
                                }}
                            />
                        </div>
                        <TextButton
                            onClick={() => {
                                navigate('/auth/forgot-password');
                            }}
                            style={{ paddingLeft: '8px',textAlign:'left' }}
                            text="Forgot Password?"
                        />
                        <FilledButton
                            loading={loading}
                            type="submit"
                            text="Sign In"
                        />
                        {errorMessage && <p style={{ color: 'red', fontSize: '0.875rem', marginTop: '0.5rem' }}>{errorMessage}</p>}
                    </form>
                    <div style={{ display: 'flex', justifyContent: "end", alignItems: 'center', marginTop: '1rem' }}>
                        <span style={{
                            padding:'0'
                        }}>Don’t have an account?</span>
                        <div style={{
                            padding:'0'
                        }}>
                        <TextButton text="Register"  onClick={() => {
                            navigate('/auth/signup');
                        }} />
                        </div>
                    </div>
                </div>
            </div>
        </AuthContainer>
    );
};

export default SignIn;