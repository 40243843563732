import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import BackButton from '../../components/buttons/BackButton';
import FilledButton from '../../components/buttons/FilledButton';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import './Profile.scss';
import axios from 'axios'
import document from '../../Assets/document-upload.svg';
import trash from '../../Assets/trash.svg';
import RoundedButton from '../../components/buttons/RoundedButton';
import RequiredMark from '../../components/widget/RequiredMark';
import { getS3BucketUrl } from '../../helper/constants';
import { formPublish, filePublish } from '../../helper/userHelper';

const ProfilePage = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [imageFileName, setImageFileName] = useState('');
    // const [formData, setFormData] = useState(new FormData());
    const userArrayString = localStorage.getItem('user');

    var user;
    var avatar;
    var Img;
    if (userArrayString != null) {
        user = JSON.parse(userArrayString);
        avatar=getS3BucketUrl(user.avatar);

    }
    
    

    const initialValues = {
        f_name: user?.f_name,
        l_name: user?.l_name,
        date_of_birth: user?.date_of_birth,
        mobile: user?.mobile,
        dail_code: user?.dail_code,
        email: user?.email,
        house_no: user?.house_no,
        block_name: user?.block_name,
        pincode: user?.pincode,
        country: user?.country,
        city: user?.city,
        state: user?.state,
        gender: user?.gender,
        band: user?.band,
        avatar: user?.avatar,
        avatar_f: null,
    };

    const validationSchema = Yup.object({
        f_name: Yup.string().required('First Name is required'),
        l_name: Yup.string().required('Last Name is required'),
        date_of_birth: Yup.date()
        .required('Date of Birth is required')
        .test(
            'is-18',
            'You must be at least 18 years old',
            value => moment().diff(moment(value), 'years') >= 18
        ),
        mobile: Yup.string().required('Phone Number is required'),
        dail_code: Yup.string().required('Country Code is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        // avatar_f: Yup.string().required('Profile photo is required'),


        house_no: Yup.string().required('House No is required'),
        block_name: Yup.string().required('Block Name is required'),
        pincode: Yup.string().required('Pincode is required'),
        country: Yup.string().required('Country is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        band: Yup.string().required('Band is required'),
        gender: Yup.string().required('Gender is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        setLoading(true);

        const formData = new FormData();

        formData.append('f_name', values.f_name);
        formData.append('l_name', values.l_name);
        formData.append('date_of_birth', values.date_of_birth);
        formData.append('mobile', values.mobile);
        formData.append('dail_code', values.dail_code);
        formData.append('email', values.email);
        formData.append('house_no', values.house_no);
        formData.append('block_name', values.block_name);
        formData.append('pincode', values.pincode);
        formData.append('country', values.country);
        formData.append('city', values.city);
        formData.append('state', values.state);
        formData.append('band', values.band);
        formData.append('gender', values.gender);

        try {
            avatar = values.avatar_f;
            // console.log(values.avatar_f);
            // formData.append('avatar', avatar);
            // const response2 = await filePublish(avatar);

            const response = await formPublish(formData);
            //console.log(response.f_name);
            setImageFileName(response.avatar);
            avatar = response.avatar;
            Img = avatar;

            const updatedUser = {
                ...user,
                ...values,
                avatar
            };

            localStorage.setItem('user', JSON.stringify(updatedUser));
            avatar=getS3BucketUrl(response.avatar);

            toast.success('Profile updated successfully');
        } catch (error) {
            toast.error('Error updating profile');
        }

        setLoading(false);
        setSubmitting(false);
    };

    return (
        <div className='container'>
            <Sidebar />
            <main className="content">
                <div style={{
                    display: 'flex',
                    textAlign: 'start',
                    alignItems: 'start',
                    alignContent: 'start'
                }}>
                    <BackButton onClick={() => navigate(-1)} />
                    <h1 className="px-3">Profile</h1>
                </div>
                <div className='form-content'>
                    <Toaster />

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue, isValid, dirty }) => (
                            <Form>
                                <div className="file">
                                    <div className="upload-file-group">
                                        <p>Your Photo</p>
                                        <label className="file-label">
                                            {(values.avatar || values.avatar_f) && (
                                                <div>
                                                    <img src={avatar} alt="Avatar" className='image' />
                                                </div>
                                            )}
                                        </label>

                                        <ErrorMessage name="avatar" component="div" className="error-message" />
                                    </div>
                                </div>
                                <div className="basicinfo">
                                    <div className="form-group">
                                        <label>First Name <RequiredMark /></label>
                                        <Field name="f_name" type="text" />
                                        <ErrorMessage name="f_name" component="div" className="error-message" />
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name <RequiredMark /></label>
                                        <Field name="l_name" type="text" />
                                        <ErrorMessage name="l_name" component="div" className="error-message" />
                                    </div>
                                    <div className="form-group">
                                        <label>Date of Birth <RequiredMark /></label>
                                        <Field name="date_of_birth" type="date" />
                                        <ErrorMessage name="date_of_birth" component="div" className="error-message" />
                                    </div>
                                    <div className="radio-group">
                                        <label>Gender <RequiredMark /></label>
                                        <div role="group" aria-labelledby="my-radio-group">
                                            <Field name="gender" type="radio" value="male" />
                                            Male
                                            <Field name="gender" type="radio" value="female" />
                                            Female


                                        </div>
                                        <ErrorMessage name="gender" component="div" className="error-message" />
                                    </div>




                                    <div className="form-group">
                                        <label>Email <RequiredMark /></label>
                                        <Field name="email" type="email" />
                                        <ErrorMessage name="email" component="div" className="error-message" />
                                    </div>


                                    <div className="form-group">
                                        <label>House No <RequiredMark /></label>
                                        <Field name="house_no" type="text" />
                                        <ErrorMessage name="house_no" component="div" className="error-message" />
                                    </div>
                                    <div className="form-group">
                                        <label>Block Name <RequiredMark /></label>
                                        <Field name="block_name" type="text" />
                                        <ErrorMessage name="block_name" component="div" className="error-message" />
                                    </div>
                                    <div className="form-group">
                                        <label>Pincode <RequiredMark /></label>
                                        <Field name="pincode" type="text" />
                                        <ErrorMessage name="pincode" component="div" className="error-message" />
                                    </div>
                                    <div className="form-group">
                                        <label>Country <RequiredMark /></label>
                                        <Field name="country" type="text" />
                                        <ErrorMessage name="country" component="div" className="error-message" />
                                    </div>
                                    <div className="form-group">
                                        <label>City <RequiredMark /></label>
                                        <Field name="city" type="text" />
                                        <ErrorMessage name="city" component="div" className="error-message" />
                                    </div>
                                    <div className="form-group">
                                        <label>State <RequiredMark /></label>
                                        <Field name="state" type="text" />
                                        <ErrorMessage name="state" component="div" className="error-message" />
                                    </div>


                                    <div className="form-group">
                                        <label>Band <RequiredMark /></label>
                                        <Field name="band" type="text" />
                                        <ErrorMessage name="band" component="div" className="error-message" />
                                    </div>

 
                                </div>

                                <div className="buttons">
                                    <div className="button-group">
                                        {!loading && <RoundedButton type="button" text="Cancel" onClick={() => navigate(-1)} />}
                                        <RoundedButton type="submit" text={isValid && dirty ? "Save" : "No changes done"} loading={loading} disabled={!isValid || !dirty || loading} />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </main>
        </div>
    );
};

export default ProfilePage;
