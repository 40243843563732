import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyOTP } from '../../helper/helper';
import toast from 'react-hot-toast';
import FilledButton from '../../components/buttons/FilledButton';
import TextButton from '../../components/buttons/TextButton';
import OtpInput from '../../components/widget/OtpInput';

const VerifyEmailOtp = ({ email, duration = 180, onResend, onChangeEmail, loading }) => {
    const [durationText, setDurationText] = useState('');
    const [otp, setOtp] = useState('');
    const [verifying, setVerifying] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [restartTimer, setRestartTimer] = useState(false); // State to trigger timer restart
    const navigate = useNavigate();

    useEffect(() => {
        let timer = duration;
        const interval = setInterval(() => {
            const minutes = Math.floor(timer / 60).toString().padStart(2, '0');
            const seconds = (timer % 60).toString().padStart(2, '0');
            setDurationText(`${minutes}:${seconds}`);
            if (--timer < 0) {
                clearInterval(interval);
                setDurationText('');
            }
        }, 1000);

        return () => clearInterval(interval); // Cleanup the interval on unmount
    }, [duration, restartTimer]); // Include restartTimer in dependencies

    const handleVerifyOTP = async (e) => {
        e.preventDefault();
        setVerifying(true);
        try {
            const response = await verifyOTP({ email, otp });
            // if (response.status === 200) {
                localStorage.setItem('token', response.data.token); // Store the token
                localStorage.setItem('user', response.data.user); // Store the user data
                setErrorMessage('');
                toast.success('OTP Verified successfully');
                navigate('/'); // Navigate to home page
            // } else if(response.status === 401){
            //     setErrorMessage('Invalid or expired otp.');
            //     toast.error('OTP verification failed.');
            // }
        } catch (error) {
            setErrorMessage(error.error);
            toast.error(error.error);
        }
        setVerifying(false);
    };

    const handleResendOTP = async () => {
        await onResend();
        setRestartTimer(prev => !prev); // Toggle restartTimer state to trigger useEffect
    };

    return (
        <div style={{ width: '100%', padding: '1rem', textAlign: 'center' }}>
            <h2 style={{ marginBottom: '1rem' }}>OTP Verification</h2>
            <form onSubmit={handleVerifyOTP} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginBottom: '1rem' }}>
                    <div style={{ marginBottom: '0.5rem' }}>
                        <label>OTP sent to {email}</label>
                    </div>
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                        style={{
                            width: '100%',
                            padding: '0.5rem',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            boxSizing: 'border-box'
                        }}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '1rem' }}>
                    {!loading && (
                        <TextButton onClick={onChangeEmail} style={{ paddingLeft: '8px' }} text="Change Email" />
                    )}
                    <TextButton onClick={handleResendOTP} text="Resend OTP" loading={loading} />
                    {!loading && <p style={{ margin: '0' }}>{durationText}</p>}
                </div>
                {!loading && (
                    <FilledButton type="submit" text="Verify OTP" loading={verifying} />
                )}
                {errorMessage && <p style={{ color: 'red', fontSize: '0.875rem' }}>{errorMessage}</p>}
            </form>
        </div>
    );
};

export default VerifyEmailOtp;
