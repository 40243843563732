import React, { useEffect, useState } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import BackButton from "../../components/buttons/BackButton";
import './Support.scss';
import { useNavigate } from "react-router-dom";
import arrowRight from '../../Assets/arrow_right.svg';
import call from '../../Assets/call.svg';
import mail from '../../Assets/email.svg';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { apiSecureHeader } from '../../helper/constants';

const SupportPage = () => {
  const navigate = useNavigate();

  const [setting, setSetting] = useState({
    email: 'potla.rama@gmail.com',
    mobile: '+91 88018 68866'
  });

  const getSettings = async () => {
    try {
      const response = await axios.get('https://hallelujahofficial.com/api/settings', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
          'secure': apiSecureHeader,
          'is_admin': true,
        },
      });

      // Check if the response status is 200 (Success)
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Unexpected status code: ' + response.status);
      }
    } catch (error) {
      // Display error message using toast
      toast.error('Failed to fetch settings: ' + error.message);
      return null;
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getSettings();
        if (settings) {
          setSetting(settings);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchSettings();
  }, []); // Empty dependency array means it runs once on mount

  const handleCallClick = () => {
    window.location.href = `tel:${setting.mobile}`;
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${setting.email}`;
  };

  return (
    <div className="container">
      <Sidebar />
      <main className="content">
        <div style={{ width: '100%', padding: '24px' }}>
          <div className="heading">
            <BackButton onClick={() => navigate(-1)} />
            <p style={{ fontSize: '40px' }}>Help or Complaint</p>
          </div>
          <div className="support-content" style={{ padding: '0 60px', marginTop: '30px' }}>
            <div className='box' onClick={handleCallClick}>
              <div className='box-left'>
                <img src={call} className='Imgicon' alt="Call Icon" />
                <div>
                  <p>Helpline Number</p>
                  <p>{setting.mobile}</p>
                </div>
              </div>
              <div className='box-right'>
                <img src={arrowRight} alt="Arrow Icon" className="boxRightImg" />
              </div>
            </div>
            <div className='box' onClick={handleEmailClick}>
              <div className='box-left'>
                <img src={mail} className='Imgicon' alt="Mail Icon" />
                <div>
                  <p>Helpline Email</p>
                  <p>{setting.email}</p>
                </div>
              </div>
              <div className='box-right'>
                <img src={arrowRight} alt="Arrow Icon" className="boxRightImg" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default SupportPage;
