import React from 'react';
import './buttons.scss'; // Import your CSS file for styling (if using external CSS)
import arrowRight from '../../Assets/arrow-right-color.svg';

const OutlinedButton = ({ text, onClick, loading = false }) => {
    return (
        <div style={{

            textAlign: 'center',
            width: '100%',
            margin: '8px'
        }}>
            {
                loading ?
                    <div className="d-flex justify-content-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>


                    : <button className="outlined-button btn-sm"


                        onClick={onClick} style={{
                            width: '100%'
                        }} >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                            padding: '0px'
                        }}>
                            {text}
                            <img src={arrowRight} alt="" height='24px'
                            
                            />

                        </div>
                    </button>
            }

        </div>
    );
}

export default OutlinedButton;
