import { useNavigate } from 'react-router-dom';
import arrowCircleLeft from '../../Assets/arrow-circle-left.svg';

const RoundedButton = ({type,text,loading = false,onClick, disabled}) => {
    const navigate = useNavigate();

    return <>
        {
            loading ?
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>


            :
            
            <button type={type} className={`sub-can-button ${disabled ? 'disabled' : ''}`}  onClick={onClick} disabled={disabled}>{text}</button>}
    </>
}


export default RoundedButton;