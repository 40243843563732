import axios from 'axios';
import { apiSecret1, apiSecret2, apiSecureHeader } from './constants';

// Helper function to get authorization headers
const getAuthHeaders = () => ({
  'secure': apiSecureHeader,
  'Authorization': `Bearer ${localStorage.getItem('token')}`,
  'is_admin': true,
});

// Fetch languages
export const fetchLanguages = async () => {
  try {
    const response = await axios.get('https://hallelujahofficial.com/api/languages/all', {
      headers: getAuthHeaders()
    });
    return response.data;
  } catch (error) {
    // console.error('Error fetching languages:', error);
    throw new Error('Failed to fetch languages');
  }
};

// Fetch genres
export const fetchGenresMusic = async (type) => {
  try {
    const response = await axios.get('https://hallelujahofficial.com/api/categories', {
      headers: getAuthHeaders(),
      params: { type }
    });
    return response.data.musics;
  } catch (error) {
    // console.error('Error fetching genres:', error);
    throw new Error('Failed to fetch genres');
  }
};

export const fetchGenresPodcast = async (type) => {
  try {
    const response = await axios.get('https://hallelujahofficial.com/api/categories', {
      headers: getAuthHeaders(),
      params: { type }
    });
    return response.data.podcasts;
  } catch (error) {
    // console.error('Error fetching genres:', error);
    throw new Error('Failed to fetch genres');
  }
};

// Publish music
export const publishMusic = async (formData, onUploadProgress, onSuccess, onError) => {
  try {
    formData.append('id', null); // Or the appropriate ID value if updating
    formData.append('secret1', apiSecret1);
    formData.append('secret2', apiSecret2);

    const response = await axios.post(
      'https://hallelujahofficial.com/api/my-music/data/save',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'secure': apiSecureHeader,
          'is_admin': true,
        },
        onUploadProgress: (progressEvent) => {
          if (onUploadProgress) {
            onUploadProgress(progressEvent);
          }
        }
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }

    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (onError) {
      onError(error);
    }

    // console.error('Error publishing music:', error);
    throw new Error('Failed to publish music');
  }
};

export async function updateMusic(formData, musicId) {
  try {
    // console.log(musicId);
    formData.append('id', musicId);
    formData.append('secret1', apiSecret1);
    formData.append('secret2', apiSecret2);
    // console.log('Form Data:', formData);
    // console.log(formData.get('image'));

    const { data } = await axios.post(
      `https://hallelujahofficial.com/api/godcast/music/update`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'secure': apiSecureHeader,
          'is_admin': true,
        }
      }
    );
    // console.log('Response Data:', data);
    return Promise.resolve({ data });
  } catch (error) {
    // console.error('Error:', error);
    throw new Error(error.response?.data?.message || 'Failed to publish music');
  }
};

export async function reviewMusic(musicId) {
  // console.log(musicId);
  try {
    const { data } = await axios.get(
      `https://hallelujahofficial.com/api/godcast/songs/send/review/` + musicId,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'secure': apiSecureHeader,
          'is_admin': true,
        }
      }
    );

    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    // console.error('Error:', error);
    throw new Error(error.response?.data?.message || 'Failed to review music');
  }
};

export async function unpublishMusic(musicId) {
  // console.log(musicId);
  try {
    const { data } = await axios.get(
      `https://hallelujahofficial.com/api/godcast/songs/unpublish/` + musicId,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'secure': apiSecureHeader,
          'is_admin': true,
        }
      }
    );

    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    // console.error('Error:', error);
    throw new Error(error.response?.data?.message || 'Failed to review music');
  }
};

export async function deleteMusic(musicId) {
  // console.log(musicId);
  try {
    const { data } = await axios.get(
      `https://hallelujahofficial.com/api/song/delete/` + musicId,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'secure': apiSecureHeader,
          'is_admin': true,
        }
      }
    );

    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    // console.error('Error:', error);
    throw new Error(error.response?.data?.message || 'Failed to delete music');
  }
};

export async function archiveMusic(musicId) {
  // console.log(musicId);
  try {
    const { data } = await axios.get(
      `https://hallelujahofficial.com/api/godcast/songs/archive/` + musicId,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'secure': apiSecureHeader,
          'is_admin': true,
        }
      }
    );

    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    // console.error('Error:', error);
    throw new Error(error.response?.data?.message || 'Failed to archive music');
  }
};

// Fetch published music
export const fetchPublishedMusic = async () => {
  try {
    const response = await axios.get('https://hallelujahofficial.com/api/godcast/songs/fetch', {
      headers: getAuthHeaders(),
      params: { 'type':'music' }
    });
    return response.data; 
  } catch (error) {
    // console.error('Error fetching published music:', error);
    throw new Error('Failed to fetch published music');
  }
};

// Fetch created episodes
export const fetchCreatedEpisodes = async () => {
  try {
    const response = await axios.get('https://hallelujahofficial.com/api/godcast/songs/fetch', {
      headers: getAuthHeaders(),
      params: { 'type':'podcasts' }
    });
    return response.data;
  } catch (error) {
    // console.error('Error fetching created episodes:', error);
    throw new Error('Failed to fetch created episodes');
  }
};



export const fetchCreatedSegments = async () => {
  try {
    const response = await axios.get('https://hallelujahofficial.com/api/godcast/segments', {
      headers: getAuthHeaders(),
      params: { 'type':'podcasts' }
    });
    return response.data;
  } catch (error) {
    // console.error('Error fetching created episodes:', error);
    throw new Error('Failed to fetch created episodes');
  }
};

export const fetchSegmentById = async (id) => {
  try {
    const response = await axios.get('https://hallelujahofficial.com/api/godcast/segments/'+ id, 
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'secure': apiSecureHeader
        }
      }
    );
    return response.data;
  } catch (error) {
    // console.error('Error fetching created episodes:', error);
    throw new Error('Failed to fetch created episodes');
  }
};

export const AddToSegments = async (formData, onUploadProgress, onSuccess, onError) => {
  try {
    // formData.append('id', null); // Or the appropriate ID value if updating
    formData.append('secret1', apiSecret1);
    formData.append('secret2', apiSecret2);
    formData.append('original_file', formData.get('file'));
    // console.log(formData.get('file'));

    const response = await axios.post(
      'https://hallelujahofficial.com/api/godcast/segments/add',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'secure': apiSecureHeader,
          'is_admin': true,
        },
        onUploadProgress: (progressEvent) => {
          if (onUploadProgress) {
            onUploadProgress(progressEvent);
          }
        }
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }

    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (onError) {
      onError(error);
    }

    // console.error('Error adding segment:', error);
    throw new Error('Failed to add segment');
  }
};

export async function deleteLibraryContent(musicId) {
  // console.log(musicId);
  try {
    const { data } = await axios.delete(
      `https://hallelujahofficial.com/api/godcast/library/delete/` + musicId,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'secure': apiSecureHeader,
          'is_admin': true,
        }
      }
    );

    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    // console.error('Error:', error);
    throw new Error(error.response?.data?.message || 'Failed to delete music');
  }
};

export const publishEpisode = async (formData, onUploadProgress, onSuccess, onError) => {
  try {
    // formData.append('id', null); // Or the appropriate ID value if updating
    // formData.append('secret1', apiSecret1); 
    // formData.append('secret2', apiSecret2);

    const response = await axios.post(
      'https://hallelujahofficial.com/api/episode/publish',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'secure': apiSecureHeader,
          'is_admin': true,
        },
        onUploadProgress: (progressEvent) => {
          if (onUploadProgress) {
            onUploadProgress(progressEvent);
          }
        }
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }

    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (onError) {
      onError(error);
    }

    // console.error('Error publishing episode:', error);
    throw new Error('Failed to publish episode');
  }
};