import axios from 'axios';

import { apiSecret1, apiSecret2, apiSecureHeader } from './constants';

const isDevelopment = process.env.NODE_ENV === 'development';


export async function getOverviewSummary(type) {
    try {
        // if (isDevelopment) console.log(type);

        if (type === 'all') {
            const [musicResponse, podcastResponse] = await Promise.all([
                axios.get('https://hallelujahofficial.com/api/godcast/overview/summary', {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type: 'music' }
                }),
                axios.get('https://hallelujahofficial.com/api/godcast/overview/summary', {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type: 'podcasts' }
                })
            ]);
            return { music: musicResponse.data, podcasts: podcastResponse.data };
        } else {
            const response = await axios.get(
                'https://hallelujahofficial.com/api/godcast/overview/summary',
                {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type }
                }
            );
            // if (isDevelopment) console.log([type], response.data);

            return { [type]: response.data };
        }
    } catch (error) {
        // console.error('Error fetching overview summary:', error);
        throw error;
    }
}

export const getOverviewPerformance = async (type, from, to, range) => {
    try {
        if (type === 'all') {
            const [musicResponse, podcastResponse] = await Promise.all([
                axios.get('https://hallelujahofficial.com/api/godcast/overview/performance', {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type: 'music', from, to, range }
                }),
                axios.get('https://hallelujahofficial.com/api/godcast/overview/performance', {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type: 'podcasts', from, to, range }
                })
            ]);
            return { music: musicResponse.data, podcasts: podcastResponse.data };
        } else {
            const response = await axios.get(
                'https://hallelujahofficial.com/api/godcast/overview/performance',
                {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type, from, to, range }
                }
            );
            // if (isDevelopment) console.log(response.data);

            return { [type]: response.data };
        }
    } catch (error) {
        // console.error('Error fetching overview performance:', error);
        throw error;
    }
};

export async function getAudienceSummary(type) {
    try {
        if (type === 'all') {
            const [musicResponse, podcastResponse] = await Promise.all([
                axios.get('https://hallelujahofficial.com/api/godcast/audience/summary', {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type: 'music' }
                }),
                axios.get('https://hallelujahofficial.com/api/godcast/audience/summary', {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type: 'podcasts' }
                })
            ]);
            return { music: musicResponse.data, podcasts: podcastResponse.data };
        } else {
            const response = await axios.get(
                'https://hallelujahofficial.com/api/godcast/audience/summary',
                {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type }
                }
            );
            // if (isDevelopment) console.log(response.data); // Logging the data instead of the response object
            return { [type]: response.data };
        }
    } catch (error) {
        // console.error('Error fetching audience summary:', error);
        throw error;
    }
}

export const getAudiencePerformance = async (type, from, to, range) => {
    try {
        if (type === 'all') {
            const [musicResponse, podcastResponse] = await Promise.all([
                axios.get('https://hallelujahofficial.com/api/godcast/audience/performance', {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type: 'music', from, to, range }
                }),
                axios.get('https://hallelujahofficial.com/api/godcast/audience/performance', {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type: 'podcasts', from, to, range }
                })
            ]);
            return { music: musicResponse.data, podcasts: podcastResponse.data };
        } else {
            const response = await axios.get(
                'https://hallelujahofficial.com/api/godcast/audience/performance',
                {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type, from, to, range }
                }
            );
            // if (isDevelopment) console.log(response.data);

            return { [type]: response.data };
        }
    } catch (error) {
        // console.error('Error fetching audience performance:', error);
        throw error;
    }
};
