import "./widget.scss";
import { ShimmerThumbnail } from "react-shimmer-effects";


const Widget = ({ title, count, link, loading = false }) => {

  if (loading) {
    return (<>
      <ShimmerThumbnail height={142} rounded width={200} />
    </>);
  }

  return (
    // <div className="left">
    <div className="widget">

      <span className="title">{title}</span>
      <span className="counter">
        {count}
      </span>
      <span className="link">{link}</span>
      {/* </div> */}

    </div>
  );
};

export default Widget;
