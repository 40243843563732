import { useNavigate } from 'react-router-dom';
import arrowCircleLeft from '../../Assets/arrow-circle-left.svg';

const BackButton = () => {
    const navigate=useNavigate();

    return <>
        <img src={arrowCircleLeft} alt="Back" onClick={() =>
            navigate(-1)} />
    </>
}

export default BackButton;