import React from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from 'recharts';
import "./barchart.scss";
import { RANGE_ALL_TIME, RANGE_MONTHLY, RANGE_NIGHENTY_DAYS, RANGE_WEEKLY } from '../../helper/constants';
import noDataImage from './../../Assets/no-data.svg'; // Update the path to your image

const BarChartComponent = ({ data, title, loading = false, onRangeChanged, currentRange }) => {

  // Conditional rendering based on data availability
  const renderContent = () => {
    
    if (!data || data.length === 0) {
      return (
        <div className="no-data">
          <img src={noDataImage} alt="No data" />
          <div>
          <p>Not enough data yet</p>

          </div>
        </div>
      );
    }
    
    return (
      <div className="barGraph">
        <ResponsiveContainer width="100%" aspect={3}>
          <BarChart data={data}>
            <XAxis dataKey="xAxis" />
            <YAxis />
            <Bar dataKey="yAxis" label={{ fontSize: 12, fill: '#FFFFFF' }} fill='#67519F' />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <>
      <h2>{title} Performance</h2>
      <div className="performance">
        <div className="subtabs-container2">
          <button
            className={currentRange === RANGE_WEEKLY ? 'active-subtab' : ''}
            onClick={() => onRangeChanged(RANGE_WEEKLY)}
            style={{ cursor: 'pointer' }}
          > Weekly</button>
          <button
            className={currentRange === RANGE_MONTHLY ? 'active-subtab' : ''}
            onClick={() => onRangeChanged(RANGE_MONTHLY)}
            style={{ cursor: 'pointer' }}
          > Monthly</button>
          <button
            className={currentRange === RANGE_NIGHENTY_DAYS ? 'active-subtab' : ''}
            onClick={() => onRangeChanged(RANGE_NIGHENTY_DAYS)}
            style={{ cursor: 'pointer' }}
          > 90 Days</button>
          <button
            className={currentRange === RANGE_ALL_TIME ? 'active-subtab' : ''}
            onClick={() => onRangeChanged(RANGE_ALL_TIME)}
            style={{ cursor: 'pointer' }}
          > All Time</button>
        </div>
        {renderContent()}
      </div>
    </>
  );
}

export default BarChartComponent;
