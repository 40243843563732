// actions/uploadActions.js
export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';

export const uploadStart = () => ({
  type: UPLOAD_START,
});

export const uploadProgress = (progress) => ({
  type: UPLOAD_PROGRESS,
  payload: progress,
});

export const uploadSuccess = () => ({
  type: UPLOAD_SUCCESS,
});

export const uploadFailure = (error) => ({
  type: UPLOAD_FAILURE,
  payload: error,
});
