import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  circularProgressClasses,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import musicImg from "../../Assets/musicnote.svg";
import "./PublishedMusicItem.scss";
import playIcon from "../../Assets/play.svg";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import { getS3BucketUrl } from "../../helper/constants";

const ITEM_HEIGHT = 48;

const PublishedMusicItem = ({
  music,
  handleClick,
  anchorEl,
  open,
  handleClose,
  handlePublish,
  handleUnpublish,
  handleDelete,
  handleEdit,
  handleArchive,
  isPublished,
  onPlayPause,
  currentlyPlaying,
}) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (currentlyPlaying) {
      setIsPlaying(!isPlaying);
    } else {
      setIsPlaying(true);
    }
    // setIsPlaying(!isPlaying);
    onPlayPause(audioRef);
  };

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    // Ensure two digits for both minutes and seconds
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <div className="musicItem" key={music.id}>
      <div className="musicItemLeft">
        <img
          src={music.image ? getS3BucketUrl(music.image) : musicImg}
          className="musicImage"
          alt={music.title || "No title"}
          style={{ cursor: music.audio_file ? "pointer" : "default" }}
        />
        <div className="musicInfo">
          <h3 style={{ width: "100%" }}>{music.title || "Test"}</h3>
          <p style={{ marginTop: "-5px" }}>
            {new Date(music.release_date).getTime() > Date.now()
              ? `Scheduled to ${new Date(
                  music.release_date
                ).toLocaleDateString()}`
              : `Released on ${new Date(
                  music.release_date
                ).toLocaleDateString()}`}
          </p>
        </div>
      </div>
      <div className="musicItemRight">
        <audio ref={audioRef} src={getS3BucketUrl(music.audio_file)}></audio>
        <IconButton
          color="primary"
          onClick={handlePlayPause}
          disabled={!music.audio_file}
        >
          {currentlyPlaying && isPlaying ? (
            <PauseCircleFilledIcon style={{ fontSize: 40 }} />
          ) : (
            <PlayCircleFilledIcon style={{ fontSize: 40 }} />
          )}
        </IconButton>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={(event) => handleClick(event, music)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              borderRadius: "8px",
              border: "1px solid #ddd",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          {music.active === 1 && [
            <MenuItem key="edit" onClick={handleEdit} disableRipple>
              <EditIcon className="icons" />
              Edit
            </MenuItem>,
            <Divider key="divider1" sx={{ my: 0.3 }} />,
            <MenuItem key="publish" onClick={handleUnpublish} disableRipple>
              <UploadIcon className="icons" />
              UnPublish
            </MenuItem>,
          ]}
          {music.active === 0 && [
            <MenuItem key="edit" onClick={handleEdit} disableRipple>
              <EditIcon className="icons" />
              Edit
            </MenuItem>,
            <Divider key="divider2" sx={{ my: 0.3 }} />,
            <MenuItem key="publish" onClick={handlePublish} disableRipple>
              <UploadIcon className="icons" />
              Publish
            </MenuItem>,
            <Divider key="divider3" sx={{ my: 0.3 }} />,
            <MenuItem key="delete" onClick={handleArchive} disableRipple>
              <DeleteIcon className="icons" />
              Archive
            </MenuItem>,
          ]}
          {music.active === -1 && [
            <MenuItem key="edit" onClick={handleEdit} disableRipple>
              <EditIcon className="icons" />
              Edit
            </MenuItem>,
            <Divider key="divider4" sx={{ my: 0.3 }} />,
            <MenuItem key="publish" onClick={handleUnpublish} disableRipple>
              <UploadIcon className="icons" />
              Unpublish
            </MenuItem>,
            <Divider key="divider5" sx={{ my: 0.3 }} />,
            <MenuItem key="delete" onClick={handleDelete} disableRipple>
              <DeleteIcon className="icons" />
              Delete
            </MenuItem>,
          ]}
        </Menu>
      </div>
    </div>
  );
};

PublishedMusicItem.propTypes = {
  music: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePublish: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default PublishedMusicItem;
