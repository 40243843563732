import React, { useEffect, useState } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import { fetchPublishedMusic, publishMusicId, publishMusic, deleteMusic, reviewMusic, unpublishMusic, archiveMusic } from '../../helper/musicHelper';
import './PublishedMusic.scss';
import { Toaster, toast } from 'react-hot-toast';
import musicImg from '../../Assets/musicnote.svg';  
import PublishedMusicItem from './PublishedMusicItem';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import checked from '../../Assets/checked.png';

Modal.setAppElement('#root');

const PublishedMusic = () => {
  const [musicList, setMusicList] = useState([]);
  const [unpublishedMusicList, setUnpublishedMusicList] = useState([]);
  const [archivedMusicList, setArchivedMusicList] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState(null);
  const [loading, setLoading] = useState(true); // Default to true while fetching data
  const [activeTab, setActiveTab] = useState('published');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const openConfirmation = (music) => {
    handleClose();
    setConfirmationIsOpen(true);
  };

  const closeConfirmation = () => {
    setSelectedMusic(null);
    setConfirmationIsOpen(false);
  };

  const handleClick = (event, music) => {
    setAnchorEl(event.currentTarget);
    setSelectedMusic(music);
  };

  const handleClose = () => { 
    setAnchorEl(null);
  };

  const handleEdit = () => {
    const serializableMusic = {
      id: selectedMusic.id,
      title: selectedMusic.title || 'Unknown',
      writers: selectedMusic.writers,
      artists_names: selectedMusic.artists_names,
      genre: selectedMusic.category_id,
      language: selectedMusic.language,
      image: selectedMusic.image,
      audio_file: selectedMusic.audio_file,
    };
    navigate('/publish-music', { state: { selectedMusic: serializableMusic } });
  };

  const handlePublish = async () => {
    if (!selectedMusic) return;

    try {
      await reviewMusic(selectedMusic.id);

      // Update the selected music's active status to 1 
      const updatedMusic = { ...selectedMusic, active: 1 };

      // Remove the selected music from the unpublished list
      setUnpublishedMusicList(prev => prev.filter(music => music.id !== selectedMusic.id));

      // Add the selected music to the published music list
      setMusicList(prev => [...prev, updatedMusic]);

   
      toast.success("Music Published Successfully");
    } catch (error) {
      toast.error('Failed to publish music');
    } finally {
      handleClose();
    }
  };

  const handleUnpublish = async () => {
    if (!selectedMusic) return;

    try {
      await unpublishMusic(selectedMusic.id);

      // Update the selected music's active status to -1 (archived)
      const updatedMusic = { ...selectedMusic, active: 0 };

      if (activeTab === 'published') {
        setMusicList(prev =>
          prev.filter(music =>
            music.id !== selectedMusic.id
          )
        );
      } else if (activeTab === 'archived') {
        setArchivedMusicList(prev =>
          prev.filter(music =>
            music.id !== selectedMusic.id
          )
        );
      }

      setUnpublishedMusicList(prev => [
        ...prev,
        updatedMusic
      ]);

      toast.success('Music Unpublished Succesfully');
    } catch (error) {
      toast.error('Failed to Unpublish music');
    } finally {
      handleClose();
    }
  };

  const handleDelete = async () => {
    if (!selectedMusic) return;

    try {
      await deleteMusic(selectedMusic.id);

      if (selectedMusic.active === 1) {
        setMusicList((prevList) => prevList.filter((music) => music.id !== selectedMusic.id));
      } else if (selectedMusic.active === 0) {
        setUnpublishedMusicList((prevList) => prevList.filter((music) => music.id !== selectedMusic.id));
      } else {
        setArchivedMusicList((prevList) => prevList.filter((music) => music.id !== selectedMusic.id));
      }
  
      toast.success('Music deleted successfully');
    } catch (error) {
      toast.error('Failed to delete music');
    } finally {
      closeConfirmation();
    }
  };

  const handleArchive = async () => {
    if (!selectedMusic) return;

    try {
      await archiveMusic(selectedMusic.id);

      // Update the selected music's active status to -1 (archived)
      const updatedMusic = { ...selectedMusic, active: -1 };

      if (selectedMusic.active === 0) {
        setUnpublishedMusicList((prevList) => prevList.filter((music) => music.id !== selectedMusic.id));
      } 

      setArchivedMusicList(prev => [
        ...prev,
        updatedMusic
      ]);
  
      toast.success('Music archived successfully');
    } catch (error) {
      toast.error('Failed to archive music');
    } finally {
      closeConfirmation();
    }
  };

  

  useEffect(() => {
    const loadPublishedMusic = async () => {
      try {
        const musicData = await fetchPublishedMusic();
        setMusicList(musicData.filter(music => music.active === 1));
        setUnpublishedMusicList(musicData.filter(music => music.active === 0));
        setArchivedMusicList(musicData.filter(music => music.active === -1));
      } catch (error) {
        toast.error('Failed to fetch music');
      } finally {
        setLoading(false);
      }
    };

    loadPublishedMusic();
  }, []);

  useEffect(() => {
    if (currentlyPlaying) {
      currentlyPlaying.audioRef.current?.pause();
      setCurrentlyPlaying(null);
    }
  }, [activeTab]);

  const handlePlayPause = (musicId, audioRef) => {
    if (currentlyPlaying && currentlyPlaying.audioRef && currentlyPlaying.id !== musicId) {
      currentlyPlaying.audioRef.current?.pause();
      currentlyPlaying.audioRef.current.currentTime = 0;
    }

    if (currentlyPlaying && currentlyPlaying.id === musicId) {
      if (currentlyPlaying.audioRef.current) {
        if (currentlyPlaying.audioRef.current.paused) {
          currentlyPlaying.audioRef.current.play();
        } else {
          currentlyPlaying.audioRef.current.pause();
        }
      }
    } else {
      audioRef.current?.play();
      setCurrentlyPlaying({ id: musicId, audioRef });

      // Listen for the 'ended' event to reset the currently playing state when the song ends
      audioRef.current.onended = () => {
        setCurrentlyPlaying(null);
      };
    }
  };

  const renderMusicList = () => {
    let list = [];
    switch (activeTab) {
      case 'published':
        list = musicList;
        break;
      case 'unpublished':
        list = unpublishedMusicList;
        break;
      case 'archived':
        list = archivedMusicList;
        break;
      default:
        list = musicList;
        break;
    }

    if (loading) {
      return <div>Loading...</div>;
    }

    if (list.length > 0) {
      return (
        <div className="musicList">
          {list.map((music) => (
            <PublishedMusicItem
              key={music.id}
              music={music}
              handleClick={handleClick}
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              handlePublish={handlePublish}
              handleUnpublish={handleUnpublish}
              handleDelete={() => openConfirmation(music)}
              handleArchive={() => openConfirmation(music)}
              handleEdit={handleEdit}
              isPublished={activeTab === 'published'}
              onPlayPause={(audioRef) => handlePlayPause(music.id, audioRef)}
              currentlyPlaying={currentlyPlaying?.id === music.id}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className="emptyState">
          <img src={musicImg} alt="No music" />
          <p>Add music to see here</p>
        </div>
      );
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <main className="content">
        <div className="homeContainer">
          <Toaster />
          <h1>My Assets (Music)</h1>
          <div className="tabs">
            <button
              className={activeTab === 'published' ? 'active' : ''}
              onClick={() => setActiveTab('published')}
            >
              Published
            </button>
            <button
              className={activeTab === 'unpublished' ? 'active' : ''}
              onClick={() => setActiveTab('unpublished')}
            >
              Unpublished
            </button>
            <button
              className={activeTab === 'archived' ? 'active' : ''}
              onClick={() => setActiveTab('archived')}
            >
              Archived
            </button>
          </div>
          {renderMusicList()}
        </div>
        <Modal
          isOpen={confirmationIsOpen}
          onRequestClose={closeConfirmation}
          contentLabel="Confirm Delete"
          style={{
            content: {
              maxHeight: '190px',
              borderRadius: '12px',
            }
          }}
        >
          <img src={checked} alt="" style={{ width: '48px', height: '48px', marginBottom: '-35px' }} />
          <h2>Confirmation</h2>
          
          <p style={{ textAlign: 'center', marginTop: '-5px', fontSize: '0.85em' }}>
            {selectedMusic && selectedMusic.active === 0 
              ? 'Are you sure you want to archive this music?' 
              : 'Are you sure you want to delete this music?'}
          </p>

          <div className="button-group">
            <button type="button" className='sub-can-button' onClick={closeConfirmation}>No</button>
            <button type="submit" className='sub-can-button' onClick={selectedMusic && selectedMusic.active === 0 ? handleArchive : handleDelete}>Yes</button>
          </div>
        </Modal>
      </main>
    </div>
  );
};

export default PublishedMusic;
