import React from 'react';
import { useNavigate } from 'react-router-dom';
import authFlash from '../../Assets/auth_flash.jpg';
import arrowRight from '../../Assets/arrow-right.svg';
import logo from '../../Assets/app_logo.png';
import Spacer from '../../components/widget/spacer';
import AuthContainer from '../../components/widget/AuthContainer';
import FilledButton from '../../components/buttons/FilledButton';
import OutlinedButton from '../../components/buttons/OutlinedButton';

const AuthPage = () => {
    const navigate = useNavigate();

    return (
        <AuthContainer>
            <div style={{
                margin: 'auto',
                textAlign: 'center',
                maxWidth: '50%',
                padding: 'auto',
                marginTop:'8em',
                boxSizing: 'border-box',
            }}>
                <div>
                    <img src={logo} alt="Logo" />
                </div>
                <h1 style={{
                    color: '#B57A25',
                    fontWeight: '700',
                    fontSize: '2rem',
                }}>
                    Godcast
                </h1>

                <Spacer />

                <FilledButton
                    onClick={() => navigate('/auth/signup')}
                    text="Sign Up"
                />

                <OutlinedButton
                    onClick={() => navigate('/auth/signin')}
                    text="Login"
                />
            </div>
        </AuthContainer>
    );
};

export default AuthPage;
