import Sidebar from "../../components/sidebar/Sidebar";
import Widget from "../../components/widget/Widget";
import BarChartComponent from "../../components/barChart/BarChart";
import PieChartComponent from "../../components/barChart/PieChart";
import AreaChartComponent from "../../components/barChart/linegraph";
import { getOverviewSummary, getOverviewPerformance, getAudienceSummary, getAudiencePerformance } from '../../helper/apiHelper';
import './Home.scss';
import LoadingSpinner from "../../components/widget/LoadingSpinner";
import { ShimmerText } from "react-shimmer-effects";
import { RANGE_WEEKLY } from "../../helper/constants";
import { useEffect, useState } from "react";




const Home = () => {


  const [activeTab, setActiveTab] = useState('overview');
  const [currentRange, setCurrentRange] = useState({ music: RANGE_WEEKLY, podcasts: RANGE_WEEKLY });
  const [loadingOverviewSummary, setLoadingOverviewSummary] = useState(false);
  const [loadingAudienceSummary, setLoadingAudienceSummary] = useState(false);
  const [loadingOverviewPerformance, setLoadingOverviewPerformance] = useState(false);
  const [loadingAudiencePerformance, setLoadingAudiencePerformance] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState('all');
  const [summaryData, setSummaryData] = useState(null);
  const [performanceData, setPerformanceData] = useState({ music: [], podcasts: [] });
  const [audienceData, setAudienceData] = useState(null);
  const [audPerfData, setAudPerfData] = useState({ music: [], podcasts: [] });

  const onRangeChanged = (range, type) => {

    setCurrentRange(prev => ({
      ...prev,
      [type]: range,
    }));
  };


  useEffect(() => {
    const fetchData = async () => {
      if (activeTab === 'overview') {
        await fetchSummaryData(activeSubTab);
        await fetchPerformanceData(activeSubTab);
      } else {
        await fetchAudienceData(activeSubTab);
        await fetchAudiencePerformanceData(activeSubTab);
      }
    };

    fetchData();
  }, [activeTab, activeSubTab,currentRange]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setActiveSubTab('all');
    setCurrentRange({ music: RANGE_WEEKLY, podcasts: RANGE_WEEKLY });
  };

  const handleSubTabChange = (subTab) => {
    setActiveSubTab(subTab);
    setCurrentRange({ music: RANGE_WEEKLY, podcasts: RANGE_WEEKLY });
  };

  const fetchSummaryData = async (type) => {
    if(summaryData!==null) return;
    setLoadingOverviewSummary(true);
    try {
      const sumData = await getOverviewSummary(type);
      setSummaryData(sumData);
    } catch (error) {
      // console.error('Failed to fetch summary data:', error);
    }
    setLoadingOverviewSummary(false);
  };

  const fetchPerformanceData = async (type) => {
    setLoadingOverviewPerformance(true);
    try {

      const date=new Date();


      const from = '2024-06-17';
      const to = '2024-07-17';
      const perfData = await getOverviewPerformance(type, from, to,currentRange);
      setPerformanceData(perfData);
    } catch (error) {
      // console.error('Failed to fetch performance data:', error);
    }
    setLoadingOverviewPerformance(false);
  };

  const fetchAudienceData = async (type) => {
    if(audienceData!==null) return;
    setLoadingAudienceSummary(true);
    try {
      const audData = await getAudienceSummary(type);
      setAudienceData(audData);
    } catch (error) {
      // console.error('Failed to fetch audience data:', error);
    }
    setLoadingAudienceSummary(false);
  };

  const fetchAudiencePerformanceData = async (type) => {
    setLoadingAudiencePerformance(true);
    try {
      const from = '2024-06-17';
      const to = '2024-07-17';
      const audPerfData = await getAudiencePerformance(type, from, to,currentRange);
      setAudPerfData(audPerfData);
    } catch (error) {
      // console.error('Failed to fetch audience performance data:', error);
    }
    setLoadingAudiencePerformance(false);
  };

  const renderSummaryWidgets = (summary = {}, loading = false) => (
    <>
      <Widget loading={loading} title="Likes" count={summary.likes ?? 0} link="View likes" />
      <Widget loading={loading} title="Plays" count={summary.plays ?? 0} link="View plays" />
      <Widget loading={loading} title="Plays Per Song" count={summary.playPerSong ?? 0} link="View plays per song" />
      <Widget loading={loading} title="Audience" count={summary.audience ?? 0} link="View audience" />
    </>
  );
  const renderAudienceContent = (activeSubTab) => {
    return (
      <>
        <div className="geoLoc">
          
          
          <h2>{activeSubTab.charAt(0).toUpperCase() + activeSubTab.slice(1)} Geographic Location</h2>
          {audienceData[activeSubTab]?.countryWise?.length > 0 ? (
            <div className="country-data-container">
              {audienceData[activeSubTab]?.countryWise.map((country, index) => (
                <div className="countryInfo" key={index}>
                  <p className="countryName">{country.name || 'Unknown'}</p>
                  <p className="audPercent">{`${country.audience_percentage.toFixed(1)}%`}</p>
                </div>
              ))}
            </div>
          ) : (
            loadingAudienceSummary ? (
              <div className="country-data-container">
                <ShimmerText line={5} gap={10} />
              </div>
            ) : (
              <p>No country data available.</p>
            )
          )}
        </div>
        <div className="audience-performance">
          <h2>{activeSubTab.charAt(0).toUpperCase() + activeSubTab.slice(1)} Audience</h2>
          <AreaChartComponent
            currentRange={currentRange[activeSubTab]}
            onRangeChanged={(range) => onRangeChanged(range, activeSubTab)}
            data={audPerfData[activeSubTab]?.data || []}
            title={`${activeSubTab.charAt(0).toUpperCase() + activeSubTab.slice(1)} Audience`}
          />
        </div>
        <div className="agender">
          <div className="age">
            {audienceData[activeSubTab]?.ageWise?.length > 0 ? (
              <PieChartComponent data={audienceData[activeSubTab]?.ageWise} title="Age" />
            ) : (
              <p>No age data available.</p>
            )}
          </div>
          <div className="age">
            {audienceData[activeSubTab]?.genderWise?.length > 0 ? (
              <PieChartComponent data={audienceData[activeSubTab]?.genderWise} title="Gender" />
            ) : (
              <p>No gender data available.</p>
            )}
          </div>
        </div>
      </>
    );
  };
  
  return (
    <div className="container">
      <Sidebar />

      <main className="content">
        <div className="homeContainer">
          <h1>Analytics</h1>
          <div className="analyticsContainer">
            <div className="tab-container">
              <button
                className={activeTab === 'overview' ? 'active-tab' : ''}
                onClick={() => handleTabChange('overview')}
                style={{ cursor: 'pointer' }}
              >
                Overview
              </button>
              <button
                className={activeTab === 'audience' ? 'active-tab' : ''}
                onClick={() => handleTabChange('audience')}
                style={{ cursor: 'pointer' }}
              >
                Audience
              </button>
            </div>
            <div className="subtabs-container">
              <button
                className={activeSubTab === 'all' ? 'active-subtab' : ''}
                onClick={() => handleSubTabChange('all')}
                style={{ cursor: 'pointer' }}
              >
                All
              </button>
              <button
                className={activeSubTab === 'music' ? 'active-subtab' : ''}
                onClick={() => handleSubTabChange('music')}
                style={{ cursor: 'pointer' }}
              >
                Music
              </button>
              <button
                className={activeSubTab === 'podcasts' ? 'active-subtab' : ''}
                onClick={() => handleSubTabChange('podcasts')}
                style={{ cursor: 'pointer' }}
              >
                Podcast
              </button>
            </div>
            <div>
              {activeTab === 'overview' && summaryData && performanceData && activeSubTab !== 'all' && (
                <>
                  <div className="MusicOverview">
                    <h2>{activeSubTab.charAt(0).toUpperCase() + activeSubTab.slice(1)} Summary</h2>
                    <div className="widgets">
                      {renderSummaryWidgets(summaryData[activeSubTab]?.summary, loadingOverviewSummary)}
                    </div>
                  </div>
                  <div>
                    <BarChartComponent currentRange={currentRange[activeSubTab]} onRangeChanged={(range) => onRangeChanged(range, activeSubTab)} loading={loadingOverviewPerformance} data={performanceData[activeSubTab]?.data} title={activeSubTab.charAt(0).toUpperCase() + activeSubTab.slice(1)} />
                  </div>
                </>
              )}
              {activeTab === 'overview' && summaryData && activeSubTab === 'all' && summaryData && (
                <>
                  <div className="MusicOverview">
                    <h2>Music Summary</h2>
                    <div className="widgets">
                      {renderSummaryWidgets(summaryData?.music?.summary, loadingOverviewSummary)}
                    </div>
                  </div>
                  <div>
                    <BarChartComponent currentRange={currentRange['music']} onRangeChanged={(range) => onRangeChanged(range, 'music')}  loading={loadingOverviewPerformance} data={performanceData.music?.data} title="Music" />
                  </div>
                  <div className="MusicOverview">
                    <h2>Podcast Summary</h2>
                    <div className="widgets">
                      {renderSummaryWidgets(summaryData?.podcasts?.summary, loadingOverviewSummary)}
                    </div>
                  </div>
                  <div>
                    <BarChartComponent currentRange={currentRange['podcasts']} onRangeChanged={(range) => onRangeChanged(range, 'podcasts')}  loading={loadingOverviewPerformance} data={performanceData?.podcasts?.data} title="Podcast" />
                  </div>
                </>
              )}
              {activeTab === 'audience' && audienceData && activeSubTab !== 'all' && (
                <>
                 {renderAudienceContent(activeSubTab)}
                </>
              )}
              {activeTab === 'audience' && audienceData && activeSubTab === 'all' && (
                <>
                 <div>
                 {renderAudienceContent('music')}
                 </div>
                 <div>
                 {renderAudienceContent('podcasts')}
                 </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
