import React, { useState, useRef } from "react";
import "./Trimmer.scss";
import TrimmableSlider from "./TrimmableSlider";

const MusicTrimmer = () => {
  const [bars, setBars] = useState([{ position: 10, trimmed: false }]);
  const [dragging, setDragging] = useState(null);
  const containerRef = useRef(null);

  const handleMouseMove = (event) => {
    if (dragging !== null && !bars[dragging].trimmed) {
      const rect = containerRef.current.getBoundingClientRect();
      const newPosition = Math.min(
        Math.max(event.clientX - rect.left, 0),
        rect.width
      );
      const updatedBars = [...bars];
      updatedBars[dragging].position = newPosition;
      setBars(updatedBars);
    }
  };

  const handleMouseUp = () => {
    setDragging(null);
  };

  const handleMouseDown = (index) => {
    setDragging(index);
  };

  const handleTrim = (index) => {
    const updatedBars = [...bars];
    updatedBars[index].trimmed = true;
    setBars(updatedBars);
    setBars((prevBars) => [
      ...prevBars,
      { position: updatedBars[index].position + 50, trimmed: false },
    ]);
  };

  return (
    <TrimmableSlider duration="100"></TrimmableSlider>
  );
};

export default MusicTrimmer;
