import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
import './pieChart.scss';

const PieChartComponent = ({ data, title }) => {
    const COLORS = [
        '#67519F', '#139CBB', '#25CBC1', '#67519F', '#1CA3EC', '#23E4AF', '#8349DD', 
        '#3BB4D9', '#2CD9A5', '#9F4FD9', '#40B8F0', '#37E8C3', '#AD53E1',
        '#4EC0EC', '#45ECD7', '#B85EEB', '#5AC4F4', '#4FF3DD', '#C565F3',
        '#62CAF7', '#57F6E4', '#CE6DF7', '#6DD1FA', '#6BFFEA', '#D877FB'
    ];

    return (
        <div className="performance">
            <h2>{title}</h2>
            <div className="piediv">
                <ResponsiveContainer width="100%" height={260} aspect={3}>
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="audience_percentage"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={40}
                            outerRadius={90}
                            labelLine={false}
                        >
                            {
                                data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))
                            }
                        </Pie>
                        <Legend
                            layout="vertical"
                            align="right"
                            verticalAlign="middle"
                            payload={data.map((entry, index) => ({
                                id: entry.name,
                                type: 'square',
                                value: title === "Age" ? `Age ${entry.name}` : `${entry.name}: ${entry.audience_percentage.toFixed(1)}%`,
                                color: COLORS[index % COLORS.length]
                            }))}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default PieChartComponent;
