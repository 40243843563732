import React, { useEffect, useState } from 'react';
import "./sidebar.scss";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../../store/store';
import checked from '../../Assets/checked.png';
import logo from '../../Assets/SidebarLogo.png';
import chart from '../../Assets/chart.svg';
import micro from '../../Assets/microphone-2.svg';
import music from '../../Assets/musicnote.svg';
import setting from '../../Assets/setting-2.svg';
import plus from '../../Assets/add-circle.svg';
import logoutIcon from '../../Assets/logout.svg';
import Modal from 'react-modal';
import { getUser } from '../../helper/userHelper';
import { getS3BucketUrl } from '../../helper/constants';
import axios from 'axios';
import { apiSecret1, apiSecret2, apiSecureHeader } from '../../helper/constants';
import toast, { Toaster } from 'react-hot-toast';
import profile from '../../Assets/profile.svg'
import { bool } from 'yup';
import Loading from '../Loading';
Modal.setAppElement('#root');

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, logout, setUser } = useAuthStore(); // Destructure auth, logout, setUser from the store
  const [activeTab, setActiveTab] = useState('/');
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // setUser(localStorage.getItem('user'));
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'user') {
        try {
          const updatedUser = event.newValue ? JSON.parse(event.newValue) : null;
          setUser(updatedUser); // Update Zustand state with new user data
        } catch (error) {
          // console.error('Error parsing user data from localStorage:', error);
          toast.error('Failed to update user data from localStorage.');
        }
      }
    };

    // Add event listener for storage change
    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [setUser]);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUser(); // Fetch from API if not in localStorage
        setUser(userData); // Store user data in Zustand
        localStorage.setItem('user', JSON.stringify(userData)); // Cache the data
        
      } catch (error) {
        // console.error('Failed to fetch user data:', error);
        // Optionally set some error state or show a notification
        handleLogout();
      }
    };

    if (!auth.user) {
      fetchUserData(); // Only fetch if auth.user is not already set
    }
  }, [auth.user, setUser]);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  const openConfirmation = () => {
    setConfirmationIsOpen(true);
  };

  const closeConfirmation = () => {
    setConfirmationIsOpen(false);
  };

  const openImageModal = () => {
    setImageModalIsOpen(true);
  };

  const closeImageModal = () => {
    setImageModalIsOpen(false);
    setSelectedImage(null); // Reset selected image
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };
  const handleImageUpload = async () => {
    if (!selectedImage) return;

    setIsLoading(true);

    const formData = new FormData();
    formData.append('avatar', selectedImage);

    try {
      const response = await axios.post('https://hallelujahofficial.com/api/godcast/user/update', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
          'secure': apiSecureHeader,
        },
      });

      if (response.status === 200) {
        toast.success('Profile picture updated successfully.');
        const updatedUser = { ...auth.user, avatar: response.data.avatar };
        setUser(updatedUser); // Update Zustand state with new avatar
        localStorage.setItem('user', JSON.stringify(updatedUser)); // Update localStorage with new avatar
        closeImageModal();
      } else {
        toast.error('Failed to update profile picture.');
      }
    } catch (error) {
      // console.error('Error updating profile picture:', error);
      toast.error('An error occurred while updating the profile picture.');
    } finally {
      setIsLoading(false);
    }
  };

  

  const handleLogout = async ()  => {
    closeConfirmation();
    setLoading(true);
    await logout();
    // setLoading();
    navigate('/auth');
  };

  const handleNavigate = (path) => {
    setActiveTab(path);
    navigate(path);
  };
  
  const avatar = auth.user ? getS3BucketUrl(auth.user.avatar) : profile;
  const name = auth.user ? auth.user.name : "Hi User";

  return (
    <div className="sidebar">
      <div className="top">
        <img src={logo} alt="Godcast Logo in sidebar" />
      </div>
      <div className="center">
        <ul>
          <li className={activeTab === '/' ? 'active' : ''} onClick={() => handleNavigate('/')}>
            <img src={chart} alt="" className="sidebarIcon" />
            <span>Analytics</span>
          </li>
          <li className={activeTab === '/created-episode' ? 'active' : ''} onClick={() => handleNavigate('/created-episode')}>
            <img src={micro} alt="" className="sidebarIcon" />
            <span>Episodes</span>
          </li>
          <li className={activeTab === '/published-music' ? 'active' : ''} onClick={() => handleNavigate('/published-music')}>
            <img src={music} alt="" className="sidebarIcon" />
            <span>Music</span>
          </li>
          <li className={activeTab === '/settings' ? 'active' : ''} onClick={() => handleNavigate('/settings')}>
            <img src={setting} alt="" className="sidebarIcon" />
            <span>Settings</span>
          </li>
        </ul>
      </div>

      <div className="bottom">
        <ul>
          <li className="addButton" onClick={() => navigate('/create-episode')}>
            <img src={plus} alt="" className="sidebarIcon" />
            <span>Add Podcast</span>
          </li>
          <li className="addButton" onClick={() => navigate('/publish-music')}>
            <img src={plus} alt="" className="sidebarIcon" />
            <span>Add Music</span>
          </li>
        </ul>
      </div>

      <div className="logout">
        <hr style={{ width: "215px" }} />
        <div className="profileBar">
          <div className="ProfileName">
            <div className="imgPr" onClick={openImageModal} style={{ cursor: 'pointer' }}>
              <img src={avatar} alt="" className='ImgIcon' />
            </div>
            <span>{name}</span>
          </div>
          <img src={logoutIcon} alt="Logout" className="sidebarIcon" onClick={openConfirmation} />
        </div>
      </div>

      <Modal
        isOpen={confirmationIsOpen}
        onRequestClose={closeConfirmation}
        contentLabel="Confirm Logout"
        style={{
          content: {
            maxHeight: '190px',
            borderRadius: '12px'
          }
        }}
      >
        <img src={checked} alt="" style={{
          width: '48px',
          height: '48px',
          marginBottom: '-35px'
        }} />
        <h2>Confirmation</h2>
        <p style={{ textAlign: 'center', marginTop: '-5px', fontSize: '0.85em' }}>
          Are you sure you want to logout?
        </p>
        <div className="button-group">
          <button type="button" className='sub-can-button' onClick={closeConfirmation}>No</button>
          <button type="submit" className='sub-can-button' onClick={handleLogout}>Yes</button>
        </div>
      </Modal>
      <Loading loading={loading} onClose={() => setLoading(false)} />

      <Modal
        isOpen={imageModalIsOpen}
        onRequestClose={closeImageModal}
        contentLabel="Update Profile Picture"
        style={{
          content: {
            maxHeight: '360px',
            maxWidth: '400px',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        }}
      >
        <h2 style={{ marginTop: '-40px' }}>Update Profile Picture</h2>
        <img src={selectedImage ? URL.createObjectURL(selectedImage) : avatar} alt="Current Profile" style={{ width: '160px', height: '160px', borderRadius: '50%', marginBottom: '20px', border: '2px solid #67519F' }} />
        <label htmlFor="fileInput" className="custom-file-input">
          Choose New Picture
        </label>
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
        <div className="button-group" style={{ display: 'flex', width: '100%' }}>
          <button type="button" className='sub-can-button' onClick={closeImageModal}>Cancel</button>
          <button type='submit' onClick={handleImageUpload} disabled={isLoading} className='sub-can-button'>
            {isLoading ? 'Uploading...' : 'Upload'}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Sidebar;

