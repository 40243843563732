import React, { useState } from 'react';
import { authenticate, registerUser } from '../../helper/helper';
import toast, { Toaster } from 'react-hot-toast';
import './Auth.scss';
import AuthContainer from '../../components/widget/AuthContainer';
import logo from '../../Assets/app_logo.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FilledButton from '../../components/buttons/FilledButton';
import AuthNavigator from '../../components/widget/AuthNavigator';
import VerifyEmailOtp from './VerifyEmailOtp';
import { Form } from 'react-bootstrap';

const SignUp = () => {
    const isDevelopment = process.env.NODE_ENV === 'development';

    const [formData, setFormData] = useState({
        f_name: '',
        l_name: '',
        date_of_birth: '',
        mobile: '',
        dail_code: '',
        email: '',
        password: '',
        confirmPassword: '',
        house_no: '',
        block_name: '',
        pincode: '',
        country: '',
        city: '',
        state: '',
        gender: '',
        band: '',
        infoSharing: false
    });
    const [isBasicDetails, setisBasicDetails] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);

    const handleChange = (e) => {
        const { name, value: inputValue, type, checked } = e.target;
        let value = inputValue;
        // Remove non-numeric characters if name is 'mobile' or 'dail_code'

        if (name === 'mobile') {
            // value = value.slice(0, 10); // Ensure mobile is max 10 digits
            // value = value.replace(/\D/g, ''); // Remove non-digit characters
        } else if (name === 'dail_code') {
            value = value.slice(0, 2); // Ensure dial code is max 2 digits
            value = value.replace(/\D/g, ''); // Remove non-digit characters
        } else if (name === 'pincode') {
            value = value.replace(/\D/g, '');
            value = value.slice(0, 6); // Ensure pincode is max 6 digits
        }
    

        if(name === 'date_of_birth'){
            validateAge(value);
        }

        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });

    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, mobile: value });
    };

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    
        if (password.length < minLength) {
            return 'Password must be at least 8 characters long.';
        }
        if (!hasUpperCase) {
            return 'Password must contain at least one uppercase letter.';
        }
        if (!hasLowerCase) {
            return 'Password must contain at least one lowercase letter.';
        }
        if (!hasNumber) {
            return 'Password must contain at least one number.';
        }
        if (!hasSpecialChar) {
            return 'Password must contain at least one special character.';
        }
        return '';
    };
    
    const validatePincode = (pincode) => {
        const pincodePattern = /^\d{6}$/;
    
        if (!pincodePattern.test(pincode)) {
            return 'Pincode must be exactly 6 digits.';
        }
    
        return '';
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        const passwordError = validatePassword(formData.password);
        if (passwordError) {
            toast.error(passwordError);
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        

        if (isBasicDetails) {
            setisBasicDetails(false);
            return;
        }
        const pincodeError = validatePincode(formData.pincode);
        if (pincodeError) {
            toast.error(pincodeError);
            return;
        }
        if (!formData.infoSharing) {
            toast.error('Please accept our terms and try again.');
            return;
        }

        if (!validateAge(formData.date_of_birth)) {
            return;
        }



        await sendOtp();
    };

    const sendOtp = async (e) => {
        const { f_name, l_name, email, mobile, dail_code, date_of_birth, password, house_no, block_name, pincode, country, city, state, gender, band } = formData;

        const credentials = {
            name: `${f_name} ${l_name}`,
            f_name,
            l_name,
            email,
            mobile: `${dail_code}${mobile}`,
            dail_code,
            date_of_birth,
            password,
            house_no,
            block_name,
            pincode,
            country,
            city,
            state,
            address: `${house_no}, ${block_name}`,
            colony: '',
            landmark: '',
            provider: 'email',
            avatar: null,
            gender,
            fcm_token: null,
            band,
            country_code: country,
        };

        setLoading(true);
        try {
            const authResponse = await authenticate(email);
            if (authResponse.data.exists) {
                toast.error('Email already exists. Please try different email or login instead');
            } else {
                await registerUser(credentials);
                toast.success('OTP sent to your email');
                setOtpSent(true);
            }

        } catch (error) {
            setErrorMessage(error.error || 'Registration failed');
            toast.error(error.error || 'Registration failed');
        }
        setLoading(false);
    }
    const validateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        if (age < 18) {
            toast.error('You must be at least 18 years old.');
            return false;
        }
        return true;
    };
    return (
        <AuthContainer>
            <div className="col-md-5 justify-content-center no-gutters" style={{ flexFlow: '1' }}>
                <div className="row justify-content-center no-gutters">
                    <div className="col-auto" style={{ padding: '0px', margin: '0px' }}>
                        <img src={logo} alt="" width={"57px"} height={"57px"} />
                    </div>
                    <h1 className="col-auto logo-name align-items-center" style={{ padding: '0px', margin: '0px' }}>
                        Godcast
                    </h1>
                </div>

                <div>
                    <Toaster />
                    {
                        otpSent ? <VerifyEmailOtp email={formData.email} loading={loading} onChangeEmail={() => setOtpSent(false)} onResend={sendOtp}></VerifyEmailOtp>
                            :
                            <div style={{ display: 'block', padding: '0px', paddingRight: '50px', margin: '0px' }}>
                                <h2 className="justify-content-center no-gutters" style={{ padding: '0px', margin: '0px', display: 'block' }}>
                                    Register To Continue
                                </h2>
                                <form onSubmit={handleSubmit} className="auth-form">
                                    <div style={{ width: '100%' }}>
                                        {isBasicDetails ? (
                                            <div>
                                                <p style={{ fontWeight: 700, fontSize: '1.5em', textAlign: 'left' }}>Basic Details</p>
                                                <div className="form-group">
                                                    <label>First Name:</label>
                                                    <input type="text" name="f_name" value={formData.f_name} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label>Last Name:</label>
                                                    <input type="text" name="l_name" value={formData.l_name} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label>Date of Birth:</label>
                                                    <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label>Phone Number:</label>
                                                    {/* <div style={{ display: 'flex', padding: '0px', margin: '0px' }}>
                                                        <input type="text" name="dail_code" placeholder="Country Code" value={formData.dail_code} onChange={handleChange} style={{ width: '48px' }} required pattern="\d*" />
                                                        <div style={{ width: '8px' }}></div>
                                                        <input type="text" name="mobile" placeholder="Phone Number" value={formData.mobile} onChange={handleChange} style={{ flexGrow: '1' }} required pattern="\d*" />
                                                    </div> */}
                                                    <div style={{ display: 'flex', padding: '0px', margin: '0px', gap: '8px'}}>
                                                        <PhoneInput
                                                            country={'India'} // Default country code
                                                            value={formData.mobile}
                                                            onChange={handlePhoneChange}
                                                            inputStyle={{ width: '100%' }}
                                                            containerStyle={{ flexGrow: '1' }}
                                                            required
                                                            dropdownStyle={{
                                                                textAlign: 'left', // Aligns the text of the country list to the left
                                                                left: 0 // Ensures the dropdown is aligned to the left of the input
                                                            }}
                                                            dropdownContainerStyle={{
                                                                display: 'block',
                                                                textAlign: 'left', // Ensures the container is aligned to the left
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                

                                                <div className="form-group">
                                                    <label>Email:</label>
                                                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label>Password:</label>
                                                    <input type="password" name="password" value={formData.password} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm Password:</label>
                                                    <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div>
                                                    <p style={{ fontWeight: 700, textAlign: 'left' }}>Address Details</p>
                                                </div>
                                                <div className="form-group">
                                                    <label>House No:</label>
                                                    <input type="text" name="house_no" value={formData.house_no} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label>Block Name:</label>
                                                    <input type="text" name="block_name" value={formData.block_name} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label>Pincode:</label>
                                                    <input type="number" name="pincode" value={formData.pincode} onChange={handleChange} required maxLength="6" pattern="\d{6}" title="Pincode must be 6 digits" />
                                                    </div>
                                                <div className="form-group">
                                                    <label>Country:</label>
                                                    <input type="text" name="country" value={formData.country} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label>City:</label>
                                                    <input type="text" name="city" value={formData.city} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label>State:</label>
                                                    <input type="text" name="state" value={formData.state} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <label>Band:</label>
                                                    <input type="text" name="band" value={formData.band} onChange={handleChange} required />
                                                </div>
                                                <div className="form-group" style={{ marginBottom: "1rem" }} >
                                                    <label style={{
                                                        fontWeight: "bold",
                                                        marginBottom: "0.5rem"
                                                    }}>Select Gender</label>
                                                    <div style={{
                                                        display: 'flex',
                                                        gap: '16px'
                                                    }}>
                                                        <label style={{
                                                            margin: '0'
                                                        }}>
                                                            <input type="radio" name="gender" value="male" checked={formData.gender === 'male'} onChange={handleChange} /> Male
                                                        </label>
                                                        <label>
                                                            <input type="radio" name="gender" value="female" checked={formData.gender === 'female'} onChange={handleChange} /> Female
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="d-flex m-1 justify-content-center" style={{ display: 'flex', margin: '8px' }}>
                                                    <Form.Check type="checkbox" name="infoSharing" checked={formData.infoSharing} onChange={handleChange} />
                                                    By signing up, you agree to Terms of Use and Privacy Policy.
                                                </div>
                                            </div>
                                        )}
                                        <FilledButton text={isBasicDetails ? "Continue" : "Sign Up"} loading={loading} />
                                        <AuthNavigator left={isBasicDetails} onRightClick={() => setisBasicDetails(false)} onLeftClick={() => setisBasicDetails(true)} />
                                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                                    </div>
                                </form>
                                <div className="row-text-button">
                                    <p className="m-0 p-0">Already have an account?</p>
                                    <a href="/auth/signin" className="px-8" style={{ paddingLeft: '8px' }}>
                                        Log In
                                    </a>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </AuthContainer>
    );
};

export default SignUp;