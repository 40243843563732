import React from 'react';
import ReactDOM from 'react-dom';
import { ClipLoader } from 'react-spinners'; // Importing a spinner component from react-spinners

const Loading = ({ loading, onClose }) => {
  if (!loading) return null; // If not loading, don't render anything

  return ReactDOM.createPortal(
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <ClipLoader color="#3498db" size={50} /> {/* Spinner component */}
      </div>
      <div style={overlayStyles} onClick={onClose} />
    </div>,
    document.body // Render the modal into the body of the document
  );
};

// Styles for the modal
const modalStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
};

const modalContentStyles = {
  position: 'relative',
  padding: '20px',
//   background: 'white',
  borderRadius: '8px',
//   boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
};

const overlayStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.3)',
  zIndex: -1,
};

export default Loading;
