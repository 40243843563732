import React from 'react';
import arrowRight from '../../Assets/arrow_right.svg';
import './SettingsItem.scss';

const SettingsItem = ({ name, icon, onClick }) => {
    return (
            <div className='box-container' onClick={onClick}>
                <div className="box-left">
                    <img src={icon} alt="" className="boxLeftImg" />
                    <p className="boxText" style={{fontSize: '20px'}}>{name}</p>
                </div>
                <div className="box-right">
                    <img src={arrowRight} alt="" className="boxRightImg"/>
                </div>
            </div> 
    );
};

export default SettingsItem;
