import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import PublishMusic from "./pages/music/PublishMusic";
import PublishedMusic from './pages/music/PublishedMusic';
import PublishEpisode from './pages/episode/PublishEpisode';
import CreateEpisode from "./pages/episode/CreateEpisode";
import Record from './pages/episode/Record';
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ForgotPassword from "./pages/auth/ForgotPassword";

import { AuthorizeUser, RedirectIfAuthenticated } from "./middleware/auth"; // Import the auth components
import AuthPage from './pages/auth/AuthPage';
import SettingsPage from './pages/settings/Settings';
import ProfilePage from './pages/auth/Profile';
import Test from './pages/test/test'; 
import CreatedEpisode from './pages/episode/CreatedEpisode';
import SupportPage from './pages/settings/Support';
import BackgroundMusic from './pages/episode/BackgroundMusic';
import EditAudio from './pages/episode/EditAudio';
import MusicTrimmer from './trimmer/Trimmer';

function App() {
  return (
    <div className="app local-bootstrap">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <AuthorizeUser>
              <Home />
            </AuthorizeUser>
          } />
          <Route path="/settings" element={
            <AuthorizeUser>
              <SettingsPage />
            </AuthorizeUser>
          } />
          <Route path="/publish-music" element={
            <AuthorizeUser>
              <PublishMusic />
            </AuthorizeUser>
          } />
          <Route path="/published-music" element={
            <AuthorizeUser>
              <PublishedMusic />
            </AuthorizeUser>
          } />
          <Route path="/create-episode" element={
            <AuthorizeUser>
              <CreateEpisode />
            </AuthorizeUser>
          } />
          <Route path="/created-episode" element={
            <AuthorizeUser>
              <CreatedEpisode />
            </AuthorizeUser>
          } />
          <Route path='/background-music' element={
            <AuthorizeUser>
              <BackgroundMusic />
            </AuthorizeUser>
          } />
          <Route path="/edit-audio" element={
            <AuthorizeUser>
              <EditAudio />
            </AuthorizeUser>
          } />
          <Route path='/publish-episode' element={
            <AuthorizeUser>
              <PublishEpisode />
            </AuthorizeUser>
          } />
          <Route path='/record' element={ 
            <AuthorizeUser>
              <Record />
            </AuthorizeUser>
          } />
          <Route path="/auth/signin" element={
            <RedirectIfAuthenticated>
              <SignIn />
            </RedirectIfAuthenticated>
          } />
          <Route path="/auth/signup" element={
            <RedirectIfAuthenticated>
              <SignUp />
            </RedirectIfAuthenticated>
          } />
          <Route path="/auth" element={
            <RedirectIfAuthenticated>
              <AuthPage />
            </RedirectIfAuthenticated>
          } />
          <Route path="/auth/forgot-password" element={
            <RedirectIfAuthenticated>
              <ForgotPassword />
            </RedirectIfAuthenticated>
          } />
          
          <Route path="/profile" element={
            <AuthorizeUser>
              <ProfilePage />
            </AuthorizeUser>
          } />
          <Route path="/support" element={
            <AuthorizeUser>
              <SupportPage />
            </AuthorizeUser>
          } />
          <Route path="/test" element={
            <Test/>
          } />
          <Route path="/trim" element={
            <MusicTrimmer/>
          } />


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
