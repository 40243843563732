import React from 'react';

const AuthNavigator = ({ left, onLeftClick, onRightClick }) => {
    return (
        <div style={{ padding: '0 1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '0.25rem' }}>
                <div
                    onClick={onLeftClick}
                    style={{
                        backgroundColor: left ? '#67519F' : '#DEE4EA',
                        height: '8px',
                        width: left ? '24px' : '12px',
                        borderRadius: '8px',
                        margin: '0.25rem',
                        cursor: 'pointer'
                    }}
                ></div>
                <div
                    onClick={onRightClick}
                    style={{
                        backgroundColor: !left ? '#67519F' : '#DEE4EA',
                        height: '8px',
                        width: left ? '12px' : '24px',
                        borderRadius: '8px',
                        margin: '0.25rem',
                        cursor: 'pointer'
                    }}
                ></div>
            </div>
        </div>
    );
}

export default AuthNavigator;
