import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import musicImg from '../../Assets/musicnote.svg';  // Change to episode-specific image if you have one
import './RecordItem.scss';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import TuneIcon from '@mui/icons-material/Tune';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { getS3BucketUrl } from '../../helper/constants';

const ITEM_HEIGHT = 48;

const RecordItem = ({ record, handleClick, anchorEl, open, handleClose, handlePublish, handleDelete, handleEdit, handleBackground, handleAudio, isLibrary, isSegment, isBackground, onPlayPause, handleAdd, currentlyPlaying, onCheckboxChange }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if(currentlyPlaying){
      setIsPlaying(!isPlaying);
    } else{
      setIsPlaying(true);
    }
    // setIsPlaying(!isPlaying);
    onPlayPause(audioRef);
  };

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    onCheckboxChange(isChecked);
  };


  return (
    <div className="RecordItem">
      <div className='RecordItemLeft'>
        {isSegment && <input type="checkbox" className="record-checkbox" onChange={handleCheckboxChange}/>}
        <img src={musicImg} className="RecordImage" alt={record.name || 'No title'} 
          style={{ cursor: record.file ? 'pointer' : 'default' }}/>
        <div className="RecordInfo">
          <h3 style={{width:'100%'}}>{record.name || record.title}</h3> 
          <p style={{marginTop: '-5px'}}>{record.created_at ? new Date(record.created_at).toLocaleDateString() : new Date(record.updated_at).toLocaleDateString()}</p>
        </div>
      </div>
      <div className='RecordItemRight'>
        <audio ref={audioRef} src={getS3BucketUrl(!record.audio_file ?  record.file :  record.audio_file)}></audio>
        <IconButton
          color="primary" 
          onClick={handlePlayPause}
          disabled={!record.audio_file && !record.file}
        >
          {currentlyPlaying && isPlaying? (
            <PauseCircleFilledIcon style={{ fontSize: 40 }} />
          ) : (
            <PlayCircleFilledIcon style={{ fontSize: 40 }} />
          )}
        </IconButton>
        <IconButton
          aria-label="more"
          id="long-button" 
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event) => handleClick(event, record)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
              borderRadius: '8px',
              border: '1px solid #ddd',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          {isSegment && (
            [
              <MenuItem key="rename" onClick={handleEdit} disableRipple>
              <EditIcon className='icons'/>
                Rename
              </MenuItem>,
              <Divider key="divider-1" className="customDivider" sx={{ my: 0.3 }} />,
              <MenuItem key="edit-audio" onClick={handleAudio} disableRipple>
                <TuneIcon className='icons' />
                  Edit Audio
              </MenuItem>,
              <Divider key="divider-2" className="customDivider" sx={{ my: 0.3 }} />,
              <MenuItem key="add-background" onClick={handleBackground} disableRipple>
               <LibraryMusicIcon className='icons' />
                 Add background
              </MenuItem>,
              <Divider key="divider-3" className="customDivider" sx={{ my: 0.3 }} />,
              <MenuItem key="delete" onClick={handleDelete} disableRipple>
              <DeleteIcon className='icons'/>
                Delete
              </MenuItem>, 
              
               
            ]
          )}
          {isLibrary && (
            [
              <MenuItem key="rename" onClick={handleEdit} disableRipple>
                <EditIcon className='icons'/>
                  Rename
              </MenuItem>,
              <Divider key="divider-1" className="customDivider" sx={{ my: 0.3 }} />,
              <MenuItem key="delete" onClick={handleDelete} disableRipple>
                <DeleteIcon className='icons'/>
                  Delete
              </MenuItem>, 
              <Divider key="divider-2" className="customDivider" sx={{ my: 0.3 }} />,
              <MenuItem key="add-episode" onClick={handleAdd} disableRipple>
                  <AddCircleOutlineIcon className='icons'/>
                  Add to Segment
              </MenuItem>
            ]
              
          )}
          {!isLibrary && !isSegment && !isBackground && (
            [
                <MenuItem key="add-episode" onClick={handleAdd} disableRipple>
                  <AddCircleOutlineIcon className='icons'/>
                  Add to Segment
                </MenuItem>
            ]
          )}
          {isBackground && (
            [
                <MenuItem key="add-background" onClick={handleAdd} disableRipple>
                  <AddCircleOutlineIcon className='icons'/>
                  Add Background
                </MenuItem>
            ]
          )}
            
          
        </Menu>
      </div>
    </div>
  );
};

RecordItem.propTypes = {
  record: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  open: PropTypes.bool.isRequired,
};

export default RecordItem;
