import React, { useState, useRef } from 'react';
import './TrimmableSlider.scss';

const TrimmableSlider = ({ duration, onSlide, saveTrimBars }) => {
  const [position, setPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [trimPositions, setTrimPositions] = useState([]);
  const containerRef = useRef(null);

  const handleMouseDown = () => setIsDragging(true);
  const handleMouseUp = () => setIsDragging(false);

  const handleMouseMove = (e) => {
    if (isDragging) {
      updatePosition(e.clientX);
    }
  };

  const handleTouchStart = () => setIsDragging(true);

  const handleTouchMove = (e) => {
    if (isDragging) {
      const touch = e.touches[0];
      updatePosition(touch.clientX);
    }
  };

  const handleTouchEnd = () => setIsDragging(false);

  const updatePosition = (clientX) => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const newPosition = Math.min(Math.max(clientX - containerRect.left, 0), containerRect.width);
    setPosition(newPosition);

    // Convert pixel position to seconds based on the duration
    const positionInSeconds = (newPosition / containerRect.width) * duration;

    // Pass the position in seconds back to the parent component (EditAudio.jsx)
    onSlide && onSlide(positionInSeconds); // Callback to parent with the slider position in seconds
  };

  const handleTrim = () => {
    const updatedTrimPositions = [...trimPositions, position];
    setTrimPositions(updatedTrimPositions);

    const positionInSeconds = pixelToSeconds(position);
    saveTrimBars && saveTrimBars(updatedTrimPositions.map(pos => pixelToSeconds(pos)));
  };

  const pixelToSeconds = (clampedPixelPosition) => {
    const containerWidth = containerRef.current.getBoundingClientRect().width;
    return (clampedPixelPosition / containerWidth) * duration;
  };

  const handleCancelTrim = (positionToRemove) => {
    setTrimPositions((prevTrimPositions) =>
      prevTrimPositions.filter((pos) => pos !== positionToRemove)
    );
  };

  return (
    <div
      className="slider-container"
      ref={containerRef}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // Stops dragging if mouse leaves the container
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {trimPositions.map((pos, index) => (
        <div key={index} className="slider-bar fixed" style={{ left: `${pos}px` }}>
          {/* Old bar is fixed at this position */}
          <button className="trim-btn" onClick={() => handleCancelTrim(pos)}>
            Cancel
          </button>
        </div>
      ))}
      <div
        className="slider-bar"
        style={{ left: `${position}px` }}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <button className="trim-btn" onClick={handleTrim}>
          Split
        </button>
      </div>
    </div>
  );
};

export default TrimmableSlider;
