import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticate, forgotPasswordSentOtp, resetPassword } from '../../helper/helper';
import { useAuthStore } from '../../store/store';
import toast, { Toaster } from 'react-hot-toast';
import AuthContainer from '../../components/widget/AuthContainer';
import FilledButton from '../../components/buttons/FilledButton';
import logo from '../../Assets/app_logo.png';
import TextButton from '../../components/buttons/TextButton';
import BackButton from '../../components/buttons/BackButton';

const ForgotPassword = () => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [durationText, setDurationText] = useState('');
    const [duration, setDuration] = useState(180);
    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [timerZero, setTimerZero] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const setEmailInStore = useAuthStore((state) => state.setEmail);
    const navigate = useNavigate();

    const startTimer = () => {
        // Clear any existing timer
        if (window.timerInterval) {
            clearInterval(window.timerInterval);
        }
    
        let timer = duration;
        window.timerInterval = setInterval(() => {
            const minutes = Math.floor(timer / 60).toString().padStart(2, '0');
            const seconds = (timer % 60).toString().padStart(2, '0');
            setDurationText(`${minutes}:${seconds}`);
            if (--timer < 0) {
                clearInterval(window.timerInterval);
                setDurationText('');
                setTimerZero(true);
            }
        }, 1000);
    };
    

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    
        if (password.length < minLength) {
            return 'Password must be at least 8 characters long.';
        }
        if (!hasUpperCase) {
            return 'Password must contain at least one uppercase letter.';
        }
        if (!hasLowerCase) {
            return 'Password must contain at least one lowercase letter.';
        }
        if (!hasNumber) {
            return 'Password must contain at least one number.';
        }
        if (!hasSpecialChar) {
            return 'Password must contain at least one special character.';
        }
        return '';
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        if (!otpSent) {
            return sendOtp(e);
        }

        const passwordError = validatePassword(newPassword);
        if (passwordError) {
            toast.error(passwordError);
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            toast.error('Passwords do not match.');
            return;
        }

        setLoading(true);
        try {
            const response = await resetPassword({ email, password: newPassword, otp });
            setEmailInStore(email);
            console.log(response);
            localStorage.setItem('token', response.data.token); // Store the token
            localStorage.setItem('user', JSON.stringify(response.data.user)); // Store the user data
            setErrorMessage('');
            toast.success('Password reset successful');
            navigate('/'); // Navigate to home page
        } catch (error) {
            setErrorMessage(error.error);
            toast.error(error.error);
        }
        setLoading(false);
    };

    const handleEmailChange = () => {
        // Reset all related states when changing email
        setOtpSent(false);
        setEmail('');
        setOtp('');
        setNewPassword('');
        setConfirmPassword('');
        setErrorMessage('');
        setDurationText('');
        setDuration(180); // Reset duration to initial value
    };

    const sendOtp = async (e) => {
        e.preventDefault();
        if (email === '') {
            setErrorMessage('Please enter a valid email address');
            toast.error('Please enter a valid email address.');
            return;
        }
        setLoading(true);
        setOtp('');
        setNewPassword('');
        setErrorMessage('');
        setConfirmPassword('');
        try {
            // Check if the email exists
            const authResponse = await authenticate(email);
            if (!authResponse.data.exists) {
                setErrorMessage('Email does not exist.');
                toast.error('Email does not exist. Please register to continue.');
                setLoading(false);
                return;
            }

            const response = await forgotPasswordSentOtp({ email });
            if (response) {
                setOtpSent(true);
                setTimerZero(false);
                startTimer();
            }
        } catch (error) {
            setErrorMessage(error || 'Failed to reset password.');
            toast.error(error || 'An unexpected error occurred.');
        }
        setLoading(false);
    };

    const handleResendOtp = async () => {
        setLoading(true);
        setDuration(180); // Reset duration
        setOtpSent(true); // Ensure OTP sent state is true to show timer and continue button
        setTimerZero(false);
        try {
            const response = await forgotPasswordSentOtp({ email });
            if (response) {
                startTimer();
            }
        } catch (error) {
            setErrorMessage(error || 'Failed to resend OTP.');
            toast.error(error || 'An unexpected error occurred.');
        }
        setLoading(false);
    };

    return (
        <AuthContainer>
            <div style={{
                maxWidth: '500px',
                margin: 'auto',
                textAlign: 'center',
                padding: '1rem'
            }}>
                <div style={{
                    textAlign: 'left'
                }}>
                    <BackButton />
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '1rem'
                }}>
                    <div style={{ marginRight: '0.5rem' }}>
                        <img src={logo} alt="" width="57px" height="57px" />
                    </div>
                    <h1 style={{
                        color: '#B57A25',
                        fontWeight: '700',
                        fontSize: '2rem',
                        margin: '0'
                    }}>
                        Godcast
                    </h1>
                </div>
                <h2 style={{ textAlign: 'center', margin: '1rem 0' }}>
                    Forgot Password
                </h2>
                <Toaster />
                <form onSubmit={handleResetPassword} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    {!otpSent && (
                        <div style={{ width: '100%', marginBottom: '1rem' }}>
                            <label style={{
                                display: 'block',
                                textAlign: 'left',
                                marginBottom: '0.5rem',
                                fontWeight: 'bold'
                            }}>Email:</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                style={{
                                    width: '100%',
                                    padding: '0.5rem',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '1rem'
                                }}
                            />
                        </div>
                    )}
                    {otpSent && (
                        <>
                            <div style={{ width: '100%', marginBottom: '1rem' }}>
                                <label style={{
                                    display: 'block',
                                    textAlign: 'left',
                                    marginBottom: '0.5rem',
                                    fontWeight: 'bold'
                                }}>OTP:</label>
                                <input
                                    type="text"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    required
                                    style={{
                                        width: '100%',
                                        padding: '0.5rem',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        fontSize: '1rem'
                                    }}
                                />
                                <div style={{
                                    textAlign: 'end',
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'space-between',
                                }}>
                                    <TextButton text="Resend OTP" onClick={handleResendOtp} />
                                    {!loading && durationText && (
                                        <span style={{ margin: '0', paddingLeft: '8px' }}>
                                            {durationText}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div style={{ width: '100%', marginBottom: '1rem' }}>
                                <label style={{
                                    display: 'block',
                                    textAlign: 'left',
                                    marginBottom: '0.5rem',
                                    fontWeight: 'bold'
                                }}>New Password:</label>
                                <input
                                    type="password"
                                   
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                    style={{
                                        width: '100%',
                                        padding: '0.5rem',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        fontSize: '1rem'
                                    }}
                                />
                            </div>
                            <div style={{ width: '100%', marginBottom: '1rem' }}>
                                <label style={{
                                    display: 'block',
                                    textAlign: 'left',
                                    marginBottom: '0.5rem',
                                    fontWeight: 'bold'
                                }}>Confirm New Password:</label>
                                <input
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    style={{
                                        width: '100%',
                                        padding: '0.5rem',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        fontSize: '1rem'
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {errorMessage && (
                        <p style={{ color: 'red', margin: '1rem 0' }}>{errorMessage}</p>
                    )}
                    {timerZero && otpSent ? (
                        <FilledButton type="button" text="Resend OTP" loading={loading} onClick={sendOtp} disabled={loading} />
                    ) : (
                        <FilledButton type="submit" loading={loading}  text={otpSent ? "Continue" : "Send OTP"} disabled={loading} />
                    )}

                </form>
            </div>
        </AuthContainer>
    );
};

export default ForgotPassword;
