import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import authFlash from '../../Assets/auth_flash.jpg';

const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return [width, height];
};

const AuthContainer = ({ children }) => {
    const [width, height] = useWindowWidth();
    const isMediumScreen = useMediaQuery({ minWidth: 768 });

    return (
        <div style={{ display: 'flex', flexDirection: 'row', margin: '0', height: '100vh', maxHeight: '100vh' }}>
            {isMediumScreen && (
                <div style={{ flex: '0 0', textAlign: 'left', margin: '0', padding: '0', paddingRight: '1em' }}>
                    <img src={authFlash} alt="" height={height} />
                </div>
            )}
            <main style={{
                flex:'1',
                padding:'16px',
                overflowY:'auto'
            }}>
                <div style={{ flex: '1', flexGrow: '1', textAlign: 'center', padding: '1em', }}>
                    {children}
                </div>
            </main>
        </div>
    );
};

export default AuthContainer;
